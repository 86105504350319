import React, {useEffect, useRef, useState} from 'react';

import {useRecoilState} from "recoil";
import Config from "../core/config";
import Cookies from "js-cookie";
import {loading} from "../atoms";
import {makeRequest} from "../utils/httpRequest";
import {useNavigate} from "react-router-dom";

import searchImg from "../img/customer_auto_filling_facilitoy_logo.png"

const apiUrl = Config.apiUrl;

const CustomerAutoFilling_End = (props, {onCustomerAdded}) => {
    const {customerKeyValid} = props;
    const navigate = useNavigate();
    const [, setLoading] = useRecoilState(loading);
    const currentCustomerAutoFilling = Cookies.get('customerAutoFilling');

    const newEntity = {
        id: undefined,
        name: '',
        address: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        instagram: '',
        birthDate: undefined,
        rg: '',
        cpf: '',
        delivery: false,
        spaceBorneDelivery: false,
        howDidYouFindUs: '',
        referral: '',
        kids: []
    };

    const [formData, setFormData] = useState(currentCustomerAutoFilling ? JSON.parse(currentCustomerAutoFilling) : newEntity);

    async function saveEntity() {

        const url = apiUrl + '/api/customers/customerKey';
        await makeRequest(url, 'POST', {
            'Content-Type': 'application/json',
        }, JSON.stringify(formData));
        Cookies.remove('customerAutoFilling');
        Cookies.remove('customerKey');

    }

    useEffect(() => {
        if (!customerKeyValid) {
            navigate(`/autocadastro/linkInvalido`);
        } else {
            if (formData) {
                try {
                    setLoading(true);
                    saveEntity();

                } catch (error) {
                    console.error('Error saving:', error);
                    alert('Error ao salvar.');
                } finally {
                    setLoading(false);
                }
            }
        }

    }, []);


    return (
        <div>
            <div style={{textAlign: 'center'}}><h2>Ficha Cadastral</h2></div>
            <div style={{textAlign: 'center'}}><img src={searchImg} alt="Logo"/></div>
            <div style={{marginTop: '10px', alignItems: 'center', display: 'flex'}}>
                <div style={{textAlign: 'center', width: '100%'}}>Cadastro realizado com sucesso!</div>

            </div>
        </div>
    );
};

export default CustomerAutoFilling_End;

