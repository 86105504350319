import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from '../../utils/httpRequest';
import '../../css/Select.css';
import ModalDiv from "../../ModalDiv";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";
import {brazilianStates} from "../../utils/utils";
import {searchCEP} from "../../core/Cep";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView"; // Import a CSS file for styling

const apiUrl = Config.apiUrl;

const AddressSelect = (props) => {
    const {onConfirm, onClose, address, addressNumber, addressComplement, neighborhood, cep, city, state} = props;

    const [, setLoading] = useRecoilState(loading);

    const currentCustomerAutoFilling = Cookies.get('customerAutoFilling');

    const inputTextCep = useRef();
    const inputTextAddress = useRef();
    const inputTextNumber = useRef();
    const inputTextAddressComplement = useRef();
    const inputTextNeighborHood = useRef();
    const inputTextCity = useRef();

    const newEntity = {
        address: address,
        addressNumber: addressNumber,
        addressComplement: addressComplement,
        neighborhood: neighborhood,
        cep: cep,
        city: city,
        state: state
    };

    const [formData, setFormData] = useState(currentCustomerAutoFilling ? JSON.parse(currentCustomerAutoFilling) : newEntity);
    const [lookedAddress, setLookedAddress] = useState('');
    const [lookedNeighborhood, setLookedNeighborhood] = useState('');


    const [cepFound, setCepFound] = useState(formData.cep);
    const [cepLooked, setCepLooked] = useState(false);

    async function searchCepAndFillAddressInputs() {

        var cepOnlyNumbers = formData.cep ? formData.cep.replace(/\D/g, "") : '';
        if(cepOnlyNumbers.length !== 8) {
            setFormData((prevData) => ({
                ...prevData,
                address: '',
                neighborhood: '',
                city: '',
                state: '',
            }));
            setCepLooked(false);
            setCepFound(false);
            alert('O CEP deve ter 8 números.');
            return;
        }

        let result = undefined;
        setLoading(true);
        try {
            result = await searchCEP(cepOnlyNumbers);
        } finally {
            setLoading(false);
        }
        if (result != null) {
            setLookedAddress(result.street);
            setLookedNeighborhood(result.neighborhood);
            setFormData((prevData) => ({
                ...prevData,
                address: result.street,
                neighborhood: result.neighborhood,
                city: result.city,
                state: result.state,
            }));
            setCepFound(true);
            if(result.street !== undefined && result.street !== '') {
                if (inputTextNumber.current) {
                    inputTextNumber.current.focus();
                }
            } else {
                alert('Algumas informações do CEP não foram encontradas. Informe seu endereço nos campos abaixo:');
                if (inputTextAddress.current) {
                    inputTextAddress.current.focus();
                }
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                address: '',
                neighborhood: '',
                city: '',
                state: '',
            }));
            setCepFound(false);
            alert('CEP não encontrado, informe seu endereço nos campos abaixo:');
            if(inputTextAddress.current) {
                inputTextAddress.current.focus();
            }
        }
        setCepLooked(true);
    }


    useEffect(() => {
        if(inputTextAddress.current) {
            if (!cepFound || (cepFound && (formData.address === undefined || formData.address !== ''))) {
                inputTextAddress.current.focus();
            }
        }
    }, [cepLooked]);
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSaveButtonClick = async () => {

        try {
            if (validateInputs()) {
                await onConfirm(formData);
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        }
    };

    function validateInputs() {

        if (!formData.cep || formData.cep === '') {
            alert('Preencha o campo cep.');
            if (inputTextCep.current) {
                inputTextCep.current.focus();
            }
            return false;
        }

        if (!formData.address || formData.address === '') {
            alert('Preencha o campo Localização.');
            if (inputTextAddress.current) {
                inputTextAddress.current.focus();
            }
            return false;
        }


        if (!formData.neighborhood || formData.neighborhood === '') {
            alert('Preencha o campo bairro.');
            if (inputTextNeighborHood.current) {
                inputTextNeighborHood.current.focus();
            }
            return false;
        }


        if (!formData.city || formData.city === '') {
            alert('Preencha o campo cidade.');
            if (inputTextCity.current) {
                inputTextCity.current.focus();
            }
            return false;
        }

        if (!formData.state || formData.state === '') {
            alert('Preencha o campo estado.');
            return false;
        }

        return true;
    }

    return (
        <ModalDiv closeModal={onClose} style_={{width: '500px'}}>
            <div className="header-label">Endereço</div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>CEP:</div>
                <div>
                    <input style={{width: '180px'}} ref={inputTextCep} type="text" id="cep" value={formData.cep}
                           name="cep"
                           onChange={handleChange}/>
                    <button onClick={searchCepAndFillAddressInputs}>Buscar CEP</button>
                </div>
            </div>

            {(cepLooked || (formData.address !== undefined && formData.address !== '')) && <>
                <div style={{marginTop: '10px', alignItems: 'center'}}>
                    <div style={{width: '250px'}}>Localização (Rua, Avenida, etc.):</div>
                    <input disabled={cepFound && lookedAddress !== undefined && lookedAddress !== ''}
                           ref={inputTextAddress} type="text" style={{width: '300px'}}
                           value={formData.address}
                           name="address"
                           onChange={handleChange} autoComplete={false}/>
                </div>

                <div style={{marginTop: '10px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Número:</div>
                    <input type="number" ref={inputTextNumber} step="1" placeholder="0" style={{width: '300px'}}
                           value={formData.addressNumber} name="addressNumber"
                           onChange={handleChange} autoComplete={false}/>
                </div>

                <div style={{marginTop: '10px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Complemento:</div>
                    <input type="text" ref={inputTextAddressComplement} style={{width: '300px'}}
                           value={formData.addressComplement}
                           name="addressComplement"
                           onChange={handleChange} autoComplete={false}/>
                </div>

                <div style={{marginTop: '10px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Bairro:</div>
                    <input disabled={cepFound && lookedNeighborhood !== undefined && lookedNeighborhood !== ''}
                           ref={inputTextNeighborHood} type="text" style={{width: '300px'}}
                           id="neighborhood"
                           value={formData.neighborhood}
                           name="neighborhood" onChange={handleChange}/>
                </div>


                <div style={{marginTop: '10px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Cidade:</div>
                    <input disabled={cepFound} ref={inputTextCity} type="text" style={{width: '300px'}} id="city"
                           value={formData.city}
                           name="city" onChange={handleChange}/>
                </div>


                <div style={{marginTop: '10px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Estado:</div>
                    <select disabled={cepFound} value={formData.state} onChange={handleChange} name="state">
                        <option value="">Selecione...</option>
                        {brazilianStates.map((estado) => (
                            <option key={estado.sigla} value={estado.sigla}>
                                {estado.sigla} - {estado.nome}
                            </option>
                        ))}
                    </select>
                </div>
            </>}
            <ActionButtonsManagerView>
                <ConfirmButton onClick={handleSaveButtonClick}/>
            </ActionButtonsManagerView>
        </ModalDiv>
    );
};

export default AddressSelect;
