import React, {useCallback, useEffect, useState} from 'react';
import CloseButton from "./CloseButton";

const ModalDiv = ({children, closeModal, style_}) => {


    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    }, []);

    let isSmallScreen = document.documentElement.clientWidth < 1000;

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <div className="overlay">
            <div className={isSmallScreen ? "select-list-small-modal" :  "select-list-modal"}
                 style={{border: '1px solid #ccc', ...style_}}>
                <CloseButton onClose={closeModal}/>
                <div style={{marginTop: '0px'}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalDiv;
