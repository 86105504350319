import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const SupplierList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    let [suppliers, setSuppliers] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/suppliers/query=' + (query && query !== '' ? (query) : '');
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setSuppliers(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        makeQuery('');
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    async function removeSupplier(id) {
        try {
            const url = apiUrl + '/api/suppliers/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };

    return (
        <>
            <div className="header-label">Consulta de Fornecedores</div>

            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
                <button onClick={onNewButtonClick}>Novo</button>
            </div>


            <table style={{width: '640px'}}>

                <colgroup>
                    <col style={{width: '100px'}}/>
                    <col style={{width: '440px'}}/>
                </colgroup>
                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Nome
                    </th>
                    <th/>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {suppliers.map((supplier, index) => (
                    <tr>
                        <td>
                            <div key={index}>{supplier.code}</div>
                        </td>
                        <td>
                            <div key={index}>{supplier.name}</div>
                        </td>
                        <td>
                            <button onClick={async () => {
                                await onEditButtonClick(supplier.id);
                            }}>Editar
                            </button>
                        </td>
                        <td>
                            <button className="remove-button" onClick={async () => {
                                // eslint-disable-next-line no-restricted-globals
                                if (window.confirm('Deseja realmente excluir?')) {
                                    await removeSupplier(supplier.id);
                                }
                            }}>Excluir
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
                {suppliers.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>

        </>
    );
};

export default SupplierList;
