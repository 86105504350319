import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {formatDateString, formatDateToAmericanFormat} from "../../utils/utils";
import Config from "../../core/config";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";
import WhatsAppButton from "../../core/WhatsAppButton";

const apiUrl = Config.apiUrl;
const KidsBirthday = () => {
    let [customerKidBirthday, setCustomerKidBirthday] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/customers/customer-kid-details?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}`;
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setCustomerKidBirthday(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery('');
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleQuery = async () => {
        await makeQuery();
    };

    return (
        <>
            <div className="header-label">Consulta de Aniversariantes</div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        Período:
                        <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>

                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            <button onClick={handleQuery}>Consultar</button>
                        </div>
                    </div>
                </div>
            </div>
            <table style={{width: '800px', height: '380px'}}>
                <colgroup>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '240px'}}/>
                    <col style={{width: '130px'}}/>
                    <col/>
                    <col style={{width: '240px'}}/>
                    <col style={{width: '120px'}}/>
                    <col style={{width: '70px'}}/>
                </colgroup>
                <tr>
                <th>
                        <div style={{textAlign: 'center'}}>Código</div>
                    </th>
                    <th>
                        Nome do Cliente
                    </th>
                    <th>
                        Telefone
                    </th>
                    <th/>
                    <th>
                        Nome da Criança
                    </th>
                    <th>
                        <div style={{textAlign: 'center'}}>Nascimento</div>
                    </th>
                    <th>
                    <div style={{textAlign: 'center'}}>Idade</div>
                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {customerKidBirthday.map((customerKidBirthday, index) => (
                    <tr>
                        <td>
                            <div style={{textAlign: 'center'}} key={index}>{customerKidBirthday.customerCode}</div>
                        </td>
                        <td>
                            <div key={index}>{customerKidBirthday.customerName}</div>
                        </td>
                        <td>
                            <div key={index}>{customerKidBirthday.customerPhoneNumber}</div>
                        </td>
                        <td>
                            {customerKidBirthday.customerPhoneNumber &&
                                <WhatsAppButton phoneNumber={customerKidBirthday.customerPhoneNumber}/>
                            }
                        </td>
                        <td>
                            <div key={index}>{customerKidBirthday.kidName}</div>
                        </td>
                        <td>
                            <div style={{textAlign: 'center'}}
                                 key={index}>{formatDateString(customerKidBirthday.kidBirthDate)}</div>
                        </td>
                        <td>
                            <div style={{textAlign: 'center'}} key={index}>{customerKidBirthday.age}</div>
                        </td>
                    </tr>
                ))}
                </tbody>
                {customerKidBirthday.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
        </>
    );
};

export default KidsBirthday;
