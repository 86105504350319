import React, {useState, useEffect} from 'react';
import '../../css/Select.css';
import {
    getProductAvailability,
    getProductRentalPriceByProductId, getProductUnavailability
} from "../../utils/requestUtils";
import ModalDiv from "../../ModalDiv";
import {
    adjustDateToBrowserTimeZone,
    calculateDaysDifference, getOnlyDate,
} from "../../utils/utils";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import './ProductAvailability.css';
import Calendar from "react-calendar";

const ProductAvailability = (props) => {
    const {product, onClose, onRequestRentProduct} = props;
    const [availability, setAvailability] = useState([]);
    const [unavailability, setUnavailability] = useState([]);
    const [firstAvailableStartDate, setFirstAvailableStartDate] = useState(undefined);

    const [days, setDays] = useState(0);
    const [productRentalPrices, setProductRentalPrices] = useState([]);
    const [, setLoading] = useRecoilState(loading);
    const [quantity, setQuantity] = useState(1);


    async function makeQuery() {

        setLoading(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 300));
            const availabilities = days > 0 ? await getProductAvailability(product.id, '', days) : [];
            setAvailability(availabilities);
            const unavailability = days > 0 ? await getProductUnavailability(product.id) : [];
            setUnavailability(unavailability);
            const firstAvailableStartDate = availabilities && availabilities.length > 0 ? availabilities
                .map(a => new Date(a.startDate))
                .reduce((min, curr) => curr < min ? curr : min) : undefined;
            setFirstAvailableStartDate(firstAvailableStartDate);
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        getProductRentalPriceByProductId(product.id).then((p) =>
            setProductRentalPrices(p));
        makeQuery();
    }, []);
    useEffect(() => {
        makeQuery();
    }, [days]);

    useEffect(() => {
        if (productRentalPrices.length > 0) {
            setDays(productRentalPrices[0].days);
        }
    }, [productRentalPrices]);

    async function handleRentalPriceSelected(value) {
        setDays(Number(value));
    }

    function handleQuantityChange(number) {
        setQuantity(number)
    }

    function isDateEnabled(date, availability, quantity) {
        if(!quantity) {
            quantity = 1;
        }
        if (availability.find(a => a.quantity >= quantity
            && calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.startDate)), adjustDateToBrowserTimeZone(date)) > 0
            && calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.endDate)), adjustDateToBrowserTimeZone(date)) <= 1)) {
            return true;
        }

        const lastPeriod = availability.find(a => a.quantity >= quantity && a.endDate === null);
        if (lastPeriod && calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(lastPeriod.startDate)), adjustDateToBrowserTimeZone(new Date(date))) > 0) {
            return true;
        }

        return false;
    }

    function isDateAvailableToDeliver(date, availability, quantity) {
        if(!quantity) {
            quantity = 1;
        }
        return availability.find(a => a.quantity >= quantity
            && (
                calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.startDate)), date) === 0
            ||
                (calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.startDate)), date) > 0
         && !a.endDate)));
    }

    function isUnavailable(date, unavailability, quantity) {
        if(!quantity) {
            quantity = 1;
        }

        return unavailability.find(a => product.stock - a.quantity < quantity
            && calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.startDate)), date) >= 0
            && calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.endDate)), date) <= 0);

    }

    function isAvailable(date, availability, quantity) {
        if(!quantity) {
            quantity = 1;
        }
        return (availability.find(a => a.quantity >= quantity
            && calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.startDate)), adjustDateToBrowserTimeZone(new Date(date))) > 0
            && calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(a.endDate)), adjustDateToBrowserTimeZone(new Date(date))) <= 1));
    }

    const tileClassName = ({date, view}) => {
        if (view === 'month') {

            const adjustedDate = getOnlyDate(new Date(date));
            adjustedDate.setDate(adjustedDate.getDate() - days);


            if(quantity > product.stock || calculateDaysDifference(adjustDateToBrowserTimeZone(new Date()), adjustDateToBrowserTimeZone(new Date(date))) < 0) {
                return 'date-disabled';
            }

            if(isUnavailable(new Date(date), unavailability, quantity)) {
                return 'date-disabled-rented';
            }

            if (isDateAvailableToDeliver(new Date(date), availability, quantity)) {
                return 'date-enabled';
            }

            return 'date-unavailable-to-deliver';
        }
        return '';
    };

    const handleDateClick = (date) => {
        if (isDateAvailableToDeliver(date, availability, quantity)) {
            onRequestRentProduct(product.id, adjustDateToBrowserTimeZone(new Date(date)), days, quantity);
        }
    }

    return (
        <ModalDiv closeModal={onClose} style_={{width: '350px'}}>
            <div className="header-label">Disponibilidade de Produtos</div>
            <div className="sub-header-label">{product.name}</div>
            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Período:</div>
                    <select value={days}
                            onChange={(e) => handleRentalPriceSelected(e.target.value)}>
                        <option value="">Selecione um período</option>
                        {productRentalPrices.map((rentalPrice, index) => (
                            <option key={index} value={rentalPrice.days}>
                                {rentalPrice.days} dias
                            </option>
                        ))}
                    </select>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>Quantidade:</div>
                    <input type="number" step="1" placeholder="1"
                           value={quantity}
                           onChange={(e) => handleQuantityChange(parseInt(e.target.value))}
                           style={{width: '100px'}}/>
                </div>
            </div>
            <Calendar
                locale="pt-BR"
                onClickDay={handleDateClick}
                tileClassName={tileClassName}
            />
        </ModalDiv>
    );
};

export default ProductAvailability;
