import React, {useState, useEffect, useRef} from 'react';
import '../../css/Select.css';
import {findProductsByQueryString} from "../../utils/requestUtils";
import ModalDiv from "../../ModalDiv";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";

const ProductSelect = (props) => {
    const {onItemClick, onClose} = props;
    const [products, setProducts] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            setProducts(await findProductsByQueryString(query));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        makeQuery('');
    }, []);

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };

    return (
        <ModalDiv closeModal={onClose} style_={{width: '500px'}}>
            <div className="header-label">Consulta de Produtos</div>
            <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
            <table style={{width: '495px', height: '480px'}}>

                <colgroup>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '450px'}}/>
                    <col style={{width: '50px'}}/>
                </colgroup>
                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Nome
                    </th>
                    <th>
                        Estoque
                    </th>
                </tr>
                {products.map((product, index) => (
                    <tr key={index}
                        className="select-list-item"
                        onClick={() => {
                            onItemClick(product.id);
                        }}
                        onMouseEnter={() => {
                            // Handle hover effect (change background color)
                        }}
                        onMouseLeave={() => {
                            // Handle hover effect (restore background color)
                        }}>
                        <td>
                            <div key={index}>{product.code}</div>
                        </td>
                        <td>
                            <div key={index}>{product.name}</div>
                        </td>
                        <td>
                            <div key={index}>{product.stock}</div>
                        </td>

                    </tr>
                ))}
                {products.length === 0 && <div className="select-list-empty">Nenhum item encontrado.</div>}
            </table>
        </ModalDiv>
    );
};

export default ProductSelect;
