import React from 'react';

const BackButton = (props) => {
    const {onClick} = props;
    return (
        <button className={"back-button"} onClick={onClick}>Voltar</button>
    );
};

export default BackButton;
