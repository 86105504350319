import './css/App.css';
import ProductList from "./components/product/ProductList";
import ProductManager from "./components/product/ProductManager";
import React, {useEffect, useRef, useState} from "react";
import CustomerList from "./components/customer/CustomerList";
import CustomerManager from "./components/customer/CustomerManager";
import RentalList from "./components/rental/RentalList";
import RentalManager from "./components/rental/RentalManager";
import PaymentMethodList from "./components/paymentMehod/PaymentMethodList";
import PaymentMethodManager from "./components/paymentMehod/PaymentMethodManager";
import ReceivableList from "./components/receivable/ReceivableList";
import {loading} from "./atoms";
import {useRecoilState} from 'recoil';
import Loading from "./core/Loading";
import Cookies from "js-cookie";
import SupplierManager from "./components/supplier/SupplierManager";
import SupplierList from "./components/supplier/SupplierList";
import PaymentManager from "./components/payment/PaymentManager";
import PaymentList from "./components/payment/PaymentList";
import ReceivableManager from "./components/receivable/ReceivableManager";
import CustomerKeyLink from "./CustomerKeyLInk";
import ProductRentedListToDeliverReturn from "./components/rental/ProductRentedList_DeliveryControl";
import CentralizedView from "./components/common/CentralizedView";
import KidsBirthday from "./components/customer/KidsBirthday";
import {useNavigate} from "react-router-dom";
import {getCompanyByUserUUID, userUUIDExists} from "./utils/requestUtils";
import ProductStatistics from "./components/product/ProductStatistics";
import CustomerEmail from "./components/customer/CustomerEmail";
import BarChart from "./components/chart/BarChart";
import DashBoard from "./components/dashBoard/DashBoard";
import CentralizedDiv from "./customerAutoFilling/CustomerAutoFillingCentralizedDiv";

const response = await fetch('/config.json');
const config = await response.json();

function App() {

    const [showDashBoard, setShowDashBoard] = useState(true);
    const [showProductManager, setShowProductManager] = useState(false);
    const [showProductList, setShowProductList] = useState(false);
    const [showProductRentedListToDeliver, setShowProductRentedListToDeliver] = useState(false);
    const [showProductRentedListToReturn, setShowProductRentedListToReturn] = useState(false);
    const [showCustomerManager, setShowCustomerManager] = useState(false);
    const [showKidsBirthday, setShowKidsBirthday] = useState(false);
    const [showCustomerEmail, setCustomerEmail] = useState(false);
    const [showProductStatistics, setShowProductStatistics] = useState(false);
    const [showPaymentManager, setShowPaymentManager] = useState(false);
    const [showReceivableManager, setShowReceivableManager] = useState(false);
    const [showSupplierManager, setShowSupplierManager] = useState(false);
    const [showCustomerList, setShowCustomerList] = useState(false);
    const [showSupplierList, setShowSupplierList] = useState(false);
    const [showRentalManager, setShowRentalManager] = useState(false);
    const [showRentalList, setShowRentalList] = useState(false);
    const [showReceivableList, setShowReceivableList] = useState(false);
    const [showPaymentList, setShowPaymentList] = useState(false);
    const [showCustomerKeyLink, setShowCustomerKeyLink] = useState(false);

    const [showPaymentMethodManager, setShowPaymentMethodManager] = useState(false);
    const [showPaymentMethodList, setShowPaymentMethodList] = useState(false);


    const [activeMenu, setActiveMenu] = useState(null);
    const [idEdit, setIdEdit] = useState(undefined);

    const [productId, setProductId] = useState(undefined);
    const [customerId, setCustomerId] = useState(undefined);
    const [date, setDate] = useState(undefined);
    const [days, setDays] = useState(undefined);
    const [quantity, setQuantity] = useState(undefined);
    const [rentalId, setRentalId] = useState(undefined);
    const [rentalProductsId, setRentalProductsId] = useState(undefined);

    const navigate = useNavigate();

    const checkIfUserIsLogged = () =>  {
        userUUIDExists().catch(r => {
            if(r.statusCode === 404) {
                navigate('/login');
            }
        });
    }

    const handleMenuClick = (menu) => {
        console.log(activeMenu + " - " + menu);
        checkIfUserIsLogged();
        if (activeMenu === menu) {
            setMenuVisible(false);
            setActiveMenu(null);
        } else {
            setActiveMenu(menu);
            if (menu === 'Cadastrar' || menu === 'Consultar' || menu === 'Cliente' || menu === 'Produto' || menu === 'Aluguel' || menu === 'Financeiro' || menu === 'Entrada') {
                setMenuVisible(true);
            } else {
                setMenuVisible(false);
            }
        }
    };

    function hideAllMenus() {
        setShowProductList(false);
        setShowProductRentedListToDeliver(false);
        setShowProductRentedListToReturn(false);
        setShowProductManager(false);
        setShowDashBoard(false);
        setShowCustomerList(false);
        setShowCustomerManager(false);
        setShowKidsBirthday(false);
        setCustomerEmail(false);
        setShowProductStatistics(false);
        setShowPaymentManager(false);
        setShowReceivableManager(false);
        setShowSupplierManager(false);
        setShowSupplierList(false);

        setShowPaymentMethodList(false);
        setShowPaymentMethodManager(false);
        setShowRentalList(false);
        setShowRentalManager(false);
        setShowReceivableList(false);
        setShowPaymentList(false);
        setShowCustomerKeyLink(false);
        setIdEdit(undefined);
        setProductId(undefined);
        setRentalId(undefined);
        setDate(undefined);
        setDays(undefined);
        setQuantity(undefined);
        setCustomerId(undefined);
    }

    const handleProductManagerClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowProductManager(true);
    };

    const handleProductListClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowProductList(true);
    };


    const handleProductRentedListToDeliverClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowProductRentedListToDeliver(true);
    };
    const handleProductRentedListToReturnClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowProductRentedListToReturn(true);
    };

    const handleCustomerManagerClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowCustomerManager(true);
    };
    const handleKidsBirthdayClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowKidsBirthday(true);
    };
    const handleCustomerEmail = () => {
        handleMenuClick(null);
        hideAllMenus();
        setCustomerEmail(true);
    };
    const handleProductStatisticsClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowProductStatistics(true);
    };

    const handlePaymentManagerClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowPaymentManager(true);
    };

    const handleReceivableManagerClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowReceivableManager(true);
    };

    const handleSupplierManagerClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowSupplierManager(true);
    };

    const handleCustomerListClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowCustomerList(true);
    };
    const handleSupplierListClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowSupplierList(true);
    };


    const handlePaymentMethodManagerClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowPaymentMethodManager(true);
    };

    const handlePaymentMethodListClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowPaymentMethodList(true);
    };


    const handleRentalManagerClick = () => {
        hideAllMenus();
        handleMenuClick(null);
        setShowRentalManager(true);
    };


    const handleRentalListClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowRentalList(true);
    };

    const handleReceivableListClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowReceivableList(true);
    };

    const handlePaymentListClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowPaymentList(true);
    };
    const handleCustomerKeyLinkClick = () => {
        handleMenuClick(null);
        hideAllMenus();
        setShowCustomerKeyLink(true);
    };

    const handleProductEditClick = (id) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowProductManager(true);
        setIdEdit(id);
    };

    const handleCustomerEditClick = (id) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowCustomerManager(true);
        setIdEdit(id);
    };

    const handlePaymentEditClick = (id) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowPaymentManager(true);
        setIdEdit(id);
    };

    const handleReceivableEditClick = (id) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowReceivableManager(true);
        setIdEdit(id);
    };


    const handleSupplierEditClick = (id) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowSupplierManager(true);
        setIdEdit(id);
    };

    const handlePaymentMethodEditClick = (id) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowPaymentMethodManager(true);
        setIdEdit(id);
    };

    const handleRentalEditClick = (id) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowRentalManager(true);
        setIdEdit(id);
    };


    const onRequestRentProduct = (productId, date, days, quantity, customerId) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowRentalManager(true);
        setProductId(productId);
        setDate(date);
        setDays(days);
        setQuantity(quantity);
        setCustomerId(customerId);
    };
    const onRequestExtendRent = (rentalId, productsId, date) => {
        handleMenuClick(null);
        hideAllMenus();
        setShowRentalManager(true);
        setRentalId(rentalId);
        setRentalProductsId(productsId);
        setDate(date);
    };


    const [isMenuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);


    const [companyName, setCompanyName] = useState(null);
    useEffect(() => {

        async function fetchData() {
            try {
                const result = await getCompanyByUserUUID();
                setCompanyName(result.name);
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            }
        }

        fetchData();

        const handleOutsideClick = (event) => {
            // Se o clique não foi dentro do menu, oculta o menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        // Adiciona um ouvinte de evento global para cliques fora do menu
        document.addEventListener('click', handleOutsideClick);

        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []); // Executa apenas uma vez durante a montagem do componente

    const toggleMenu = () => {
        setMenuVisible(!isMenuVisible);
    };

    function handleExitClickButton() {
        Cookies.remove('userUUID');
        navigate('/login');
    }

    function isCentralizedViewView() {
        return showPaymentManager ||
            showPaymentList ||
            showReceivableManager ||
            showReceivableList ||
            showSupplierManager ||
            showSupplierList ||
            showRentalManager ||
            showRentalList ||
            showProductManager ||
            showProductList ||
            showProductRentedListToDeliver ||
            showProductRentedListToReturn ||
            showCustomerManager ||
            showKidsBirthday ||
            showCustomerEmail ||
            showProductStatistics ||
            showCustomerList ||
            showPaymentMethodManager ||
            showPaymentMethodList;
    }

    return (
        <div>
            {companyName}
            <div ref={menuRef} style={{
                zIndex: 1000,
                width: '100%',
                backgroundColor: "#333",
                display: "flex",
                flexWrap: "wrap",
                minHeight: "50px",
                alignItems: "center"
            }}>

                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Cliente');
                        }}
                        className={'menu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                    >
                        Cliente
                    </div>
                    {isMenuVisible && activeMenu === 'Cliente' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => handleCustomerListClick()}
                                    className={'submenu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                                >
                                    Consultar Cliente
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleCustomerManagerClick()}
                                    className={'submenu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                                >
                                    Cadastrar Cliente
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleKidsBirthdayClick()}
                                    className={'submenu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                                >
                                    Consultar Aniversariantes
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleCustomerEmail()}
                                    className={'submenu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                                >
                                    Listagem de Emails
                                </div>
                            </li>
                        </ul>
                    )}
                </div>

                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Produto');
                        }}
                        className={'menu-button ' + (activeMenu === 'Produto' ? 'active' : '')}
                    >
                        Produto
                    </div>
                    {isMenuVisible && activeMenu === 'Produto' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => handleProductListClick()}
                                    className={'submenu-button ' + (activeMenu === 'Produto' ? 'active' : '')}
                                >
                                    Consultar Produto
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleProductManagerClick()}
                                    className={'submenu-button ' + (activeMenu === 'Produto' ? 'active' : '')}
                                >
                                    Cadastrar Produto
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleProductStatisticsClick()}
                                    className={'submenu-button ' + (activeMenu === 'Produto' ? 'active' : '')}
                                >
                                    Estatística de Produtos
                                </div>
                            </li>
                        </ul>
                    )}
                </div>


                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Aluguel');
                        }}
                        className={'menu-button ' + (activeMenu === 'Aluguel' ? 'active' : '')}
                    >
                        Aluguel
                    </div>
                    {isMenuVisible && activeMenu === 'Aluguel' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => handleRentalListClick()}
                                    className={'submenu-button ' + (activeMenu === 'Aluguel' ? 'active' : '')}
                                >
                                    Consultar Aluguel
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleRentalManagerClick()}
                                    className={'submenu-button ' + (activeMenu === 'Aluguel' ? 'active' : '')}
                                >
                                    Cadastrar Aluguel
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleProductRentedListToDeliverClick()}
                                    className={'submenu-button ' + (activeMenu === 'Controle de Entregas' ? 'active' : '')}
                                >
                                    Controle de Entregas
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleProductRentedListToReturnClick()}
                                    className={'submenu-button ' + (activeMenu === 'Controle de Devoluções' ? 'active' : '')}
                                >
                                    Controle de Devoluções
                                </div>
                            </li>
                        </ul>
                    )}
                </div>


                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Financeiro');
                        }}
                        className={'menu-button ' + (activeMenu === 'Financeiro' ? 'active' : '')}
                    >
                        Financeiro
                    </div>
                    {isMenuVisible && activeMenu === 'Financeiro' && (
                        <ul className="submenu">
                            <li>
                                <div
                                    onClick={() => handleReceivableListClick()}
                                    className={'submenu-button ' + (activeMenu === 'Financeiro' ? 'active' : '')}
                                >
                                    Contas a Receber
                                </div>
                            </li>

                            <li>
                                <div
                                    onClick={() => handlePaymentListClick()}
                                    className={'submenu-button ' + (activeMenu === 'Financeiro' ? 'active' : '')}
                                >
                                    Contas a Pagar
                                </div>
                            </li>

                            <li>
                                <div
                                    onClick={() => handlePaymentMethodListClick()}
                                    className={'submenu-button ' + (activeMenu === 'Formas de Pagamento' ? 'active' : '')}
                                >
                                    Formas de Pagamento
                                </div>
                            </li>
                        </ul>
                    )}
                </div>


                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Entrada');
                        }}
                        className={'menu-button ' + (activeMenu === 'Entrada' ? 'active' : '')}
                    >
                        Entrada
                    </div>
                    {isMenuVisible && activeMenu === 'Entrada' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => handleSupplierListClick()}
                                    className={'submenu-button ' + (activeMenu === 'Consultar Fornecedor' ? 'active' : '')}
                                >
                                    Fornecedores
                                </div>
                            </li>

                        </ul>
                    )}
                </div>

                <div>
                    <div
                        onClick={() => {
                            toggleMenu();
                            handleCustomerKeyLinkClick();
                        }}
                        className="menu-button"
                    >
                        Link Cadastro Cliente
                    </div>
                </div>

                <div>
                    <div
                        onClick={() => {
                            toggleMenu();
                            handleExitClickButton();
                        }}
                        className="menu-button"
                    >
                        Sair
                    </div>
                </div>

            </div>
            {/*<CentralizedView>*/}
            {/*<div style={{width: '700px', height: '280px'}}>*/}
            {/*    <ChartComponent/>*/}
            {/*</div>*/}
            {/*</CentralizedView>*/}
            {isCentralizedViewView() &&
                <CentralizedView>
                    {showPaymentManager &&
                        <PaymentManager id={idEdit} onSave={handlePaymentListClick} onBack={handlePaymentListClick}/>}
                    {showPaymentList && <PaymentList onEditButtonClick={handlePaymentEditClick}
                                                     onNewButtonClick={handlePaymentManagerClick}/>}
                    {showReceivableManager && <ReceivableManager id={idEdit} onSave={handleReceivableListClick}
                                                                 onBack={handleReceivableListClick}/>}
                    {showReceivableList && <ReceivableList onEditButtonClick={handleReceivableEditClick}
                                                           onNewButtonClick={handleReceivableManagerClick}/>}
                    {showSupplierManager && <SupplierManager id={idEdit} onSave={handleSupplierListClick}
                                                             onBack={handleSupplierListClick}/>}
                    {showSupplierList && <SupplierList onEditButtonClick={handleSupplierEditClick}
                                                       onNewButtonClick={handleSupplierManagerClick}/>}
                    {showRentalManager && <RentalManager id={idEdit} rentalItemProp={
                        rentalId || productId ? {
                            rentalId: rentalId,
                            productId: productId,
                            rentalProductsId: rentalProductsId,
                            date: date,
                            days: days,
                            quantity: quantity,
                            customerId: customerId,
                        } : undefined} onSave={handleRentalListClick} onBack={handleRentalListClick}/>}
                    {showRentalList && <RentalList onEditButtonClick={handleRentalEditClick}
                                                   onNewButtonClick={handleRentalManagerClick}/>}
                    {showProductManager &&
                        <ProductManager id={idEdit} onSave={handleProductListClick} onBack={handleProductListClick}/>}
                    {showProductList && <ProductList onEditButtonClick={handleProductEditClick}
                                                     onNewButtonClick={handleProductManagerClick}
                                                     onRequestRentProduct={onRequestRentProduct}/>}
                    {showProductRentedListToDeliver && <ProductRentedListToDeliverReturn delivered={false}/>}
                    {showProductRentedListToReturn &&
                        <ProductRentedListToDeliverReturn delivered={true} onRequestExtendRent={onRequestExtendRent}/>}
                    {showCustomerManager && <CustomerManager id={idEdit} onSave={handleCustomerListClick}
                                                             onBack={handleCustomerListClick}/>}
                    {showKidsBirthday && <KidsBirthday/>}
                    {showCustomerEmail && <CustomerEmail/>}
                    {showProductStatistics && <ProductStatistics/>}
                    {showCustomerList && <CustomerList onEditButtonClick={handleCustomerEditClick}
                                                       onNewButtonClick={handleCustomerManagerClick}/>}
                    {showPaymentMethodManager && <PaymentMethodManager id={idEdit} onSave={handlePaymentMethodListClick}
                                                                       onBack={handlePaymentMethodListClick}/>}
                    {showPaymentMethodList && <PaymentMethodList onEditButtonClick={handlePaymentMethodEditClick}
                                                                 onNewButtonClick={handlePaymentMethodManagerClick}/>}
                </CentralizedView>
            }
            {showCustomerKeyLink && <CustomerKeyLink onClose={() => setShowCustomerKeyLink(false)}/>}

            {showDashBoard &&
                <div style={{display: 'block'}}><DashBoard/></div>}
        </div>
    );
}

export default App;
