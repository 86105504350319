import React, {useEffect, useRef, useState} from 'react';

import {useRecoilState} from "recoil";
import Config from "../core/config";
import Cookies from "js-cookie";
import {loading} from "../atoms";
import {useNavigate} from "react-router-dom";
import searchImg from "../img/customer_auto_filling_facilitoy_logo.png"


const apiUrl = Config.apiUrl;
const currentUrl = window.location.href;
const CustomerAutoFilling_Name = (props, {onCustomerAdded}) => {
    const {customerKeyValid} = props;
    const navigate = useNavigate();
    const [, setLoading] = useRecoilState(loading);
    const currentCustomerAutoFilling = Cookies.get('customerAutoFilling');
    const customerKey = Cookies.get('customerKey');
    const inputTextName = useRef();

    const newEntity = {
        id: undefined,
        customerKey: customerKey,
        name: '',
        address: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        instagram: '@',
        birthDate: undefined,
        rg: '',
        cpf: '',
        delivery: false,
        spaceBorneDelivery: false,
        howDidYouFindUs: '',
        referral: '',
        kids: []
    };

    const [formData, setFormData] = useState(currentCustomerAutoFilling ? JSON.parse(currentCustomerAutoFilling) : newEntity);
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        if(!customerKeyValid) {
            navigate(`/autocadastro/linkInvalido`);
        }
        if (inputTextName.current) {
            inputTextName.current.focus();
        }
    }, []);
    function validateInputs() {

        if (!formData.name || formData.name === '') {
            if (inputTextName.current) {
                inputTextName.current.focus();
            }
            alert('Preencha o campo nome.');
            return false;
        }

        if(formData.name.split(' ').length < 2) {
            if (inputTextName.current) {
                inputTextName.current.focus();
            }
            alert('Informe o nome completo.');
            return false;
        }

        return true;
    }

    async function save() {
        Cookies.set('customerAutoFilling', JSON.stringify(formData));
        navigate(`/autocadastro/documentos`);
    }


    async function handleContinue() {
        if (validateInputs()) {
            await save();
        }
    }

    return (
        <div>
            <div style={{textAlign: 'center'}}><h2>Ficha Cadastral</h2></div>
                <div style={{textAlign: 'center'}}> <img src={searchImg} alt="Logo"/></div>
            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome completo:</div>
                <input ref={inputTextName} type="text" style={{width: '300px'}} value={formData.name} name="name"
                       onChange={handleChange}
                    //    onKeyUp={(e) => {
                    // if (e.key === 'Enter') {
                    //     handleContinue();
                    // }}}
                />
            </div>


            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}></div>
                <button onClick={handleContinue}>Continuar</button>
            </div>
        </div>
    );
};

export default CustomerAutoFilling_Name;

