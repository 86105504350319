import React, {useEffect, useRef, useState} from 'react';
import {useRecoilState} from "recoil";
import {loading} from "../atoms";
import Config from "../core/config";
import Cookies from "js-cookie";
import {formatPhoneNumber} from "../core/telephone";
import {validateEmail} from "../core/email";
import DatePicker from "react-datepicker";
import {adjustDateToBrowserTimeZone, getOnlyDate} from "../utils/utils";
import {formatCPF, validateCPF} from "../core/cpf";
import {isOver} from "../core/date";
import {useNavigate} from "react-router-dom";

import searchImg from "../img/customer_auto_filling_facilitoy_logo.png"
const CustomerAutoFilling_Documents = (props, {onCustomerAdded}) => {
    const {customerKeyValid} = props;
    const navigate = useNavigate();
    const currentCustomerAutoFilling = Cookies.get('customerAutoFilling');

    const inputTextRg = useRef();
    const inputTextCpf = useRef();
    const inputTextBirthDate = useRef();


    const newEntity = {
        id: undefined,
        name: '',
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        instagram: '@',
        birthDate: undefined,
        rg: '',
        cpf: '',
        delivery: false,
        spaceBorneDelivery: false,
        howDidYouFindUs: '',
        referral: ''
    };


    const [formData, setFormData] = useState(currentCustomerAutoFilling ? JSON.parse(currentCustomerAutoFilling) : newEntity);



    useEffect(() => {
        if(!customerKeyValid) {
            navigate(`/autocadastro/linkInvalido`);
        }
        formData.birthDate = formData.birthDate ? adjustDateToBrowserTimeZone(new Date(formData.birthDate)) :  undefined;
        if(inputTextRg.current) {
            inputTextRg.current.focus();
        }
    }, [])
    const handleSaveButtonClick = async () => {

        try {
            if (validateInputs()) {
                await save();
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        }
    };

    function validateInputs() {

        if (!formData.rg || formData.rg === '') {
            alert('Preencha o campo RG.');
            if (inputTextRg.current) {
                inputTextRg.current.focus();
            }
            return false;
        }

        if (!formData.cpfCnpj || formData.cpfCnpj === '') {
            alert('Preencha o campo cpf.');
            if (inputTextCpf.current) {
                inputTextCpf.current.focus();
            }
            return false;
        }

        if (!validateCPF(formData.cpfCnpj)) {
            alert('CPF inválido.');
            if (inputTextCpf.current) {
                inputTextCpf.current.focus();
            }
            return false;
        }

        if (!formData.birthDate || formData.birthDate === '') {
            alert('Preencha o campo data de nascimento.');
            if (inputTextBirthDate.current) {
                inputTextBirthDate.current.setFocus();
            }
            return false;
        }

        if (!isOver(formData.birthDate, 18)) {
            alert('Data de nascimento inválida. Menor de 18 anos.');
            if (inputTextBirthDate.current) {
                inputTextBirthDate.current.setFocus();
            }
            return false;
        }

        if (isOver(formData.birthDate, 100)) {
            alert('Data de nascimento inválida. Data muito antiga.');
            if (inputTextBirthDate.current) {
                inputTextBirthDate.current.setFocus();
            }
            return false;
        }

        return true;
    }

    async function save() {
        Cookies.set('customerAutoFilling', JSON.stringify(formData));
        navigate('/autocadastro/endereco')
    }


    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            birthDate: date ? getOnlyDate(date) :date
        }));
    };

    function handleBackButtonClick() {
        navigate(`/autocadastro/nome`);
    }

    return (
        <div>
            <div style={{textAlign: 'center'}}><h2>Ficha Cadastral - Documentos</h2></div>

                <div style={{textAlign: 'center'}}> <img src={searchImg} alt="Logo"/></div>
            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>RG:</div>
                <input ref={inputTextRg} type="text" id="rg" value={formData.rg} name="rg" onChange={handleChange}/>
            </div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>CPF:</div>
                <input ref={inputTextCpf} type="text" id="cpfCnpj" value={formData.cpfCnpj} name="cpfCnpj" maxLength="14"
                       onChange={(e) => {
                           e.target.value = formatCPF(e.target.value);
                           handleChange(e);
                       }}/>
            </div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Nascimento:</div>
                <DatePicker
                    className="datepicker"
                    ref={inputTextBirthDate}
                    dateFormat="dd/MM/yyyy"
                    name="birthDate"
                    selected={formData.birthDate ? adjustDateToBrowserTimeZone(new Date(formData.birthDate)) : undefined}
                    onChange={handleBirthDateChange}
                    isClearable
                />
            </div>


            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <button onClick={handleBackButtonClick}>Voltar</button>
                <button onClick={handleSaveButtonClick}>Continuar</button>
            </div>


        </div>
    );
};

export default CustomerAutoFilling_Documents;
