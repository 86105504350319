import React from 'react';
import ReactDOM from 'react-dom';
import {RecoilRoot} from 'recoil';
import Cookies from "js-cookie";
import {customerKeyExists} from "./utils/requestUtils";
import AppRouter from "./AppRouter";

const currentUrl = window.location.href;

const urlParts = currentUrl.split('/');
const customerKey = urlParts.length > 1 ? urlParts[urlParts.length - 1] : undefined;
const lastUrlPart = urlParts.length > 1 ? urlParts[urlParts.length - 2] : undefined;

let customerKeyValid = false;

if (lastUrlPart.startsWith('autocadastro') && customerKey) {

    const response = await customerKeyExists(customerKey);

    if (response.valid) {
        customerKeyValid = true;
        Cookies.set('customerKey', customerKey);
    }
}

if (window.location.pathname === '/') {
    window.location.pathname = '/app';
}

ReactDOM.render(
    <RecoilRoot>
        {<AppRouter customerKeyValid={customerKeyValid}/>}
    </RecoilRoot>,
    document.getElementById('root')
);


