import React from 'react';
import searchImg from "../img/icons8-google-maps-32.png"

const GoogleMapsButton = ({address, city, cep}) => {
    const handleGoogleMapsClick = (e) => {
        e.preventDefault();
        const formattedAddress = encodeURIComponent(address + ", " + city + ", " + cep);

        const googleMapsLink = 'https://www.google.com/maps/dir//' + formattedAddress;
        window.open(googleMapsLink, '_blank');
    };

    return (
        <>
            <a href="#" className="GoogleMaps-button" onClick={handleGoogleMapsClick}>
                <img src={searchImg} alt="Whats App"/>
            </a>
        </>
    );
};

export default GoogleMapsButton;
