import React, {useEffect, useRef, useState} from 'react';

import {useRecoilState} from "recoil";
import Config from "../core/config";
import Cookies from "js-cookie";
import {loading} from "../atoms";

import {useNavigate} from "react-router-dom";
import searchImg from "../img/customer_auto_filling_facilitoy_logo.png"
import {isSpaceBorneDelivery} from "../utils/requestUtils";

const CustomerAutoFilling_Name = (props, {onCustomerAdded}) => {
    const {customerKeyValid} = props;
    const currentCustomerAutoFilling = Cookies.get('customerAutoFilling');
    const customerKey = Cookies.get('customerKey');
    const navigate = useNavigate();

    const inputTextHowDidYouFindUs = useRef();
    const [spaceBorneDelivery, setSpaceBorneDelivery] = useState(false);

    const newEntity = {
        id: undefined,
        name: '',
        address: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        instagram: '',
        birthDate: undefined,
        rg: '',
        cpf: '',
        delivery: false,
        spaceBorneDelivery: false,
        howDidYouFindUs: '',
        referral: '',
        kids: []
    };

    const [formData, setFormData] = useState(currentCustomerAutoFilling ? JSON.parse(currentCustomerAutoFilling) : newEntity);

    useEffect(() => {
        if(!customerKeyValid) {
            navigate(`/autocadastro/linkInvalido`);
        }
        if(inputTextHowDidYouFindUs.current) {
            inputTextHowDidYouFindUs.current.focus();
        }
        isSpaceBorneDelivery(customerKey).then((response) => setSpaceBorneDelivery(response));

    },[])
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    function validateInputs() {

        return true;
    }

    async function save() {
        Cookies.set('customerAutoFilling', JSON.stringify(formData));
        navigate('/autocadastro/fim');
    }


    async function handleContinue() {
        if (validateInputs()) {
            await save();
        }
    }

    function handleBackButtonClick() {
        navigate(`/autocadastro/crianca/${(formData.kids.length-1)}`);
    }

    function handleRadioChange() {
        setFormData(prevState => ({
            ...prevState,
            spaceBorneDelivery: !prevState.spaceBorneDelivery,
        }));
    }

    return (
        <div>
            <div style={{textAlign: 'center'}}><h2>Ficha Cadastral</h2></div>

                <div style={{textAlign: 'center'}}><img src={searchImg} alt="Logo"/></div>
            {spaceBorneDelivery &&
                <div style={{marginTop: '10px', alignItems: 'center'}}>
                    <div> Entrega com Astronauta?<br/>Nosso entregador se fantasia de astronauta para a diversão dos
                        pequenos. Não é cobrado adicional pelo serviço.
                    </div>

                    <div>
                        <label>
                            <input
                                type="radio"
                                name="spaceBorneOptionYes"
                                value="sim"
                                checked={formData.spaceBorneDelivery}
                                onChange={handleRadioChange}
                            />
                            Sim
                        </label>

                        <label>
                            <input
                                type="radio"
                                name="spaceBorneOptionNo"
                                value="nao"
                                checked={!formData.spaceBorneDelivery}
                                onChange={handleRadioChange}
                            />
                            Não
                        </label>
                    </div>
                </div>
            }

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '300px'}}>Como conheceu os nossos serviços?</div>
                <input ref={inputTextHowDidYouFindUs} type="text" style={{width: '300px'}} id="howDidYouFindUs"
                       value={formData.howDidYouFindUs}
                       name="howDidYouFindUs" onChange={handleChange}/>
            </div>

            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '300px'}}>Caso tenha sido por BLOGUEIRA ou INDICAÇÂO, gostaríamos de saber quem:</div>
                <input style={{width: '300px'}} type="text" id="referral" value={formData.referral} name="referral" onChange={handleChange}/>
            </div>


            <div style={{marginTop: '10px', alignItems: 'center'}}>
                <div style={{width: '180px'}}></div>
                <button onClick={handleBackButtonClick}>Voltar</button>
                <button onClick={handleContinue}>Continuar</button>
            </div>
        </div>
    );
};

export default CustomerAutoFilling_Name;

