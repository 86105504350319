import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {getProductById, getProductRentalPriceByProductId} from "../../utils/requestUtils";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import BackButton from "../common/BackButton";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const ProductManager = (props, {onProductAdded}) => {
    const {id, onBack, onSave} = props;
    const [, setLoading] = useRecoilState(loading);

    const newEntity = {
        id: undefined,
        name: '',
        stock: 1,
        availableStock: 0,
        costValue: 0,
        supplier: '',
        brand: '',
        recommendedAge: '',
        details: '',
        customerOrientations: '',
        maxWeight: 0,
        pieces: []

    };
    const [formData, setFormData] = useState(newEntity);
    const newProductRentalPrice = {
        product: {id: 0, name: ''},
        days: 7,
        price: 0
    };

    const newProductPiece =
        {pieceName: '', quantity: 1};
    const [productRentalPrices, setProductRentalPrices] = useState([newProductRentalPrice]);
    const [productPieces, setProductPieces] = useState([newProductPiece]);

    const [productRentalPricesToRemove, setProductRentalPricesToRemove] = useState([]);
    const [productPiecesToRemove, setProductPiecesToRemove] = useState([]);

    /*
    const [selectedFile, setSelectedFile] = useState(null);    
    const [imageUrl, setImageUrl] = useState(null);


    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setImageUrl(URL.createObjectURL(file));

        setFormData((prevData) => ({
            ...prevData,
            image: file,
        }));
    };


     */
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : type === 'file' ? e.target.files[0] : value,
        }));
    };

    useEffect(() => {

        if (productRentalPrices[productRentalPrices.length - 1].days > 0 && productRentalPrices[productRentalPrices.length - 1].price > 0) {
            const productRentalPrice = newProductRentalPrice;
            productRentalPrice.days = productRentalPrices[productRentalPrices.length - 1].days + productRentalPrices[productRentalPrices.length - 1].days;
            setProductRentalPrices([...productRentalPrices, productRentalPrice]);
        }
    }, [productRentalPrices]);

    useEffect(() => {

        if (productPieces.length === 0 || productPieces[productPieces.length - 1].pieceName && productPieces[productPieces.length - 1].pieceName !== '') {
            setProductPieces([...productPieces, newProductPiece]);
        }
    }, [productPieces]);

    async function makeQuery() {
        if (id) {
            try {
                const product = await getProductById(id);
                setFormData(product);
                setProductPieces(product.pieces)

                const productRentalPrices = await getProductRentalPriceByProductId(id);
                setProductRentalPrices([...productRentalPrices, newProductRentalPrice]);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
            setProductRentalPrices([newProductRentalPrice]);
            setProductPieces([newProductPiece])
        }
    }

    useEffect(() => {
        makeQuery();
    }, [id]);


    function validateInputs() {

        if (!formData.name || formData.name === '') {
            alert('Preencha o campo nome.');
            return false;
        }

        if(formData.costValue === undefined || formData.costValue === null || formData.costValue === '') {
            alert('Preencha o campo valor de compra.');
            return false;
        }

        if(formData.stock === undefined || formData.stock === null || formData.stock === '') {
            alert('Preencha o campo estoque.');
            return false;
        }

        return true;
    }

    async function saveEntity() {

        const userUUID = Cookies.get('userUUID');
        formData.pieces = productPieces.filter((p) => p.pieceName && p.pieceName !== '');
        const url = apiUrl + '/api/products';
        const productResponse = await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid':userUUID,
        }, JSON.stringify(formData));

        if (productResponse.id) {
            const currentProductRentalPrices = productRentalPrices.slice(0, -1);
            for (let i = 0; i < currentProductRentalPrices.length; i++) {
                const productRentalPrice = currentProductRentalPrices[i];
                productRentalPrice.product = productResponse;
                const url = apiUrl + '/api/product_rental_prices';
                await makeRequest(url, productRentalPrice.id ? 'PUT' : 'POST', {
                    'Content-Type': 'application/json',
                    'Useruuid':Cookies.get('userUUID')
                }, JSON.stringify(productRentalPrice));
            }
        }

        for (let i = 0; i < productRentalPricesToRemove.length; i++) {
            const productRentalPrice = productRentalPricesToRemove[i];
            if (productRentalPrice.id > 0) {
                const url = apiUrl + '/api/product_rental_prices/' + productRentalPrice.id;
                await makeRequest(url, 'DELETE', {
                    'Content-Type': 'application/json',
                     'Useruuid':Cookies.get('userUUID')
                }, JSON.stringify(productRentalPrice));
            }
        }

    }


    const onConfirm = async () => {
        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                onSave();
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function clearEntity() {
        setFormData(newEntity);
        setProductRentalPrices([newProductRentalPrice]);
        setProductPieces([newProductPiece]);
    }

    function handleDaysChange(index, days) {
        setProductRentalPrices((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    days:
                    days
                } : item
            )
        );

    }

    function handlePieceNameChange(index, pieceName) {
        setProductPieces((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    pieceName:
                    pieceName
                } : item
            )
        );

    }

    function handlePieceQuantityChange(index, quantity) {
        setProductPieces((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    quantity:
                    quantity
                } : item
            )
        );

    }


    function handlePriceChange(index, price) {
        setProductRentalPrices((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    price:
                    price,
                } : item
            )
        );

    }

    const removeItem = (index) => {
        setProductRentalPricesToRemove([...productRentalPricesToRemove, productRentalPrices[index]]);
        const novaLista = [...productRentalPrices];
        novaLista.splice(index, 1);
        setProductRentalPrices(novaLista);
    };
    const removePiece = (index) => {
        const novaLista = [...productPieces];
        novaLista.splice(index, 1);
        setProductPieces(novaLista);
    };

    return (
        <div>

            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Produto</div>


            {/*{formData.id && (*/}
            {/*    <div style={{marginTop: '5px', alignItems: 'center'}}>*/}
            {/*        <div style={{width: '180px'}}>Código:</div>*/}
            {/*        <input type="text" style={{backgroundColor: '#CCCCCC'}} value={formData.id} disabled={true}/>*/}
            {/*    </div>)}*/}

            {/*<div style={{marginTop: '5px', alignItems: 'center'}}>*/}
            {/*    <div style={{width: '180px'}}>Foto:</div>*/}
            {/*    <input*/}
            {/*        type="file"*/}
            {/*        accept="image/*"*/}
            {/*        id="fileInput"*/}
            {/*        style={{display: 'none'}}*/}
            {/*        onChange={handleFileChange}*/}
            {/*    />*/}
            {/*    <button*/}
            {/*        onClick={handleButtonClick}*/}
            {/*    >*/}
            {/*        Escolher Foto*/}
            {/*    </button>*/}

            {/*    {imageUrl && <div><img src={imageUrl} style={{maxHeight: '100px'}} alt="Imagem selecionada"/></div>}*/}
            {/*</div>*/}

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Nome:</div>
                    <input id="productName" type="text" style={{width: '300px'}} value={formData.name} name="name"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>Estoque:</div>
                    <input type="number" style={{width: '100px'}} step="1" placeholder="1" value={formData.stock}
                           name="stock"
                           onChange={handleChange}/>
                </div>

                {/*<div style={{marginTop: '5px', alignItems: 'center'}}>*/}
                {/*    <div style={{width: '180px'}}>Estoque disponível:</div>*/}
                {/*    <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"*/}
                {/*           value={formData.availableStock}*/}
                {/*           name="availableStock"*/}
                {/*           onChange={handleChange}/>*/}
                {/*</div>*/}

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Valor de Compra:</div>
                    <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                           value={formData.costValue}
                           name="costValue"
                           onChange={handleChange}/>
                </div>

            </div>
            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div>Fornecedor:</div>
                    <input type="text" style={{width: '200px'}} value={formData.supplier} name="supplier"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>Marca:</div>
                    <input type="text" style={{width: '200px'}} value={formData.brand} name="brand"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>Idade Recomendada:</div>
                    <input type="text" style={{width: '150px'}} value={formData.recommendedAge} name="recommendedAge"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>Peso Máximo em KG:</div>
                    <input type="number" style={{width: '150px'}} step="1" placeholder="0"
                           value={formData.maxWeight}
                           name="maxWeight"
                           onChange={handleChange}/>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Detalhes:</div>
                    <textarea style={{width: '300px', height: '50px'}} value={formData.details} name="details"
                              onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Orientações ao Cliente:</div>
                    <textarea style={{width: '300px', height: '50px'}} value={formData.customerOrientations}
                              name="customerOrientations"
                              onChange={handleChange}/>
                </div>
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '250px'}}>Preços por período de Locação:</div>


                <table style={{height:'125px'}}>
                    <thead>
                    <tr>
                        <th>Dias Locação</th>
                        <th>Preço (R$)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody style={{overflowY: 'auto'}}>
                    {productRentalPrices.map((item, index) => (
                        <tr key={item.id}>
                            <td>
                                <input key={item.id} type="number" step="1" placeholder="0"
                                       value={item.days}
                                       onChange={(e) => handleDaysChange(index, parseFloat(e.target.value))}/>
                            </td>
                            <td>
                                <input key={item.id} type="number" step="0.01" placeholder="0.00"
                                       value={item.price}
                                       onChange={(e) => handlePriceChange(index, parseFloat(e.target.value))}/>
                            </td>
                            <td>
                                {index < productRentalPrices.length - 1 && (
                                    <button className="remove-button"
                                            onClick={() => removeItem(index)}>Excluir</button>)}
                            </td>
                        </tr>
                    ))}


                    </tbody>
                </table>
            </div>
            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Peças:</div>

                <table style={{height: '125px'}}>
                    <thead>
                    <tr>
                        <th>Nome da Peça</th>
                        <th>Quantidade</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody style={{overflowY: 'auto'}}>
                    {productPieces.map((item, index) => (
                        <tr>
                            <td>
                                <input type="text"
                                       value={item.pieceName}
                                       onChange={(e) => handlePieceNameChange(index, e.target.value)}/>
                            </td>
                            <td>
                                <input key={item.id} type="number" step="1" placeholder="1"
                                       value={item.quantity}
                                       onChange={(e) => handlePieceQuantityChange(index, parseInt(e.target.value))}/>
                            </td>
                            <td>
                                {index < productPieces.length - 1 && (
                                    <button className="remove-button"
                                            onClick={() => removePiece(index)}>Excluir</button>)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <ActionButtonsManagerView>
                <BackButton onClick={onBack}/>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>


        </div>
    );
};

export default ProductManager;
