import React, {useEffect, useRef, useState} from 'react';
import {useRecoilState} from "recoil";
import {loading} from "../atoms";
import Config from "../core/config";
import Cookies from "js-cookie";
import {formatPhoneNumber} from "../core/telephone";
import {validateEmail} from "../core/email";
import DatePicker from "react-datepicker";
import {adjustDateToBrowserTimeZone, getOnlyDate} from "../utils/utils";
import {formatCPF, validateCPF} from "../core/cpf";
import {isDateAfterCurrent, isOver} from "../core/date";
import {useNavigate, useParams} from "react-router-dom";

import searchImg from "../img/customer_auto_filling_facilitoy_logo.png"
const CustomerAutoFilling_Kids = (props, {onCustomerAdded}) => {
    const {customerKeyValid} = props;
    const {kidIndex} = useParams();
    const navigate = useNavigate();

    const [, setLoading] = useRecoilState(loading);
    const [addNewKid, setAddNewKid] = useState(false);

    const currentCustomerAutoFilling = Cookies.get('customerAutoFilling');

    const inputTextKidName = useRef();
    const inputTextKidBirthDate = useRef();

    const newEntity = {
        id: undefined,
        name: '',
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        instagram: '@',
        birthDate: undefined,
        rg: '',
        cpf: '',
        delivery: false,
        spaceBorneDelivery: false,
        howDidYouFindUs: '',
        referral: '',
        kids: []
    };

    const newKid = {name: '', birthDate: undefined};


    const [formData, setFormData] = useState(currentCustomerAutoFilling ? JSON.parse(currentCustomerAutoFilling) : newEntity);
    const [currentKid, setCurrentKid] = useState(newKid);

    useEffect(() => {
        if(!customerKeyValid) {
            navigate(`/autocadastro/linkInvalido`);
        }
        currentKid.birthDate = currentKid.birthDate ? new Date(currentKid.birthDate) : undefined;
        setCurrentKid(currentKid);
        setAddNewKid(Number(kidIndex) === 0 && formData.kids.length === 0);
        if(inputTextKidName.current) {
            inputTextKidName.current.focus();
        }
    }, [])

    useEffect(() => {
        const kid = Number(kidIndex) === formData.kids.length ? newKid : formData.kids[kidIndex];
        kid.birthDate = kid.birthDate ? new Date(kid.birthDate) : undefined;
        setCurrentKid(kid);
        setAddNewKid(Number(kidIndex) === 0 && formData.kids.length === 0);
    }, [kidIndex])
    const handleSaveButtonClick = async () => {

        try {
            if (validateInputs()) {
                await save();
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        }
    };

    function validateInputs() {


        if (!currentKid.name || currentKid.name === '') {
            alert('Preencha o campo nome.');
            if(inputTextKidName.current) {
                inputTextKidName.current.focus();
            }
            return false;
        }

        if (!currentKid.birthDate || currentKid.birthDate === '') {
            alert('Preencha o campo data de nascimento.');
            if(inputTextKidBirthDate.current) {
                inputTextKidBirthDate.current.setFocus();
            }
            return false;
        }

        if (isOver(currentKid.birthDate, 10)) {
            alert('Data de nascimento inválida. Maior que 10 anos.');
            if(inputTextKidBirthDate.current) {
                inputTextKidBirthDate.current.setFocus();
            }
            return false;
        }

        if (isDateAfterCurrent(getOnlyDate(currentKid.birthDate))) {
            alert('Data de nascimento inválida. Data posterior à data de hoje.');
            if(inputTextKidBirthDate.current) {
                inputTextKidBirthDate.current.setFocus();
            }
            return false;
        }

        return true;
    }

    async function save() {
        currentKid.birthDate = currentKid.birthDate ? adjustDateToBrowserTimeZone(new Date(currentKid.birthDate)) : undefined;
        if (!kidIndex) {
            formData.kids.push(currentKid);
        } else {
            formData.kids[kidIndex] = currentKid;
        }
        Cookies.set('customerAutoFilling', JSON.stringify(formData));
        navigate(`/autocadastro/crianca/${Number(kidIndex) + 1}`);

    }


    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setCurrentKid((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleBirthDateChange = (date) => {
        setCurrentKid((prevData) => ({
            ...prevData,
            birthDate: date ? getOnlyDate(date) : date
        }));
    };

    function handleContinue() {
        navigate(`/autocadastro/sobre`);
    }

    function handleAddButtonClick() {
        setAddNewKid(true);
    }

    function handleBackButtonClick() {
        if(Number(kidIndex) === 0) {
            navigate(`/autocadastro/contato`);
        } else {
            navigate(`/autocadastro/crianca/${(kidIndex - 1)}`);
        }
    }

    function handleRemoveButtonClick() {
        const novaLista = [...formData.kids];
        novaLista.splice(Number(kidIndex), 1);
        const newFormData = ({
            ...formData,
            kids: novaLista
        });
        setFormData(newFormData);
        Cookies.set('customerAutoFilling', JSON.stringify(newFormData));
        if(Number(kidIndex) === 0) {
            if(novaLista.length > 0) {
                setCurrentKid(novaLista[0]);
            } else {
                setCurrentKid(newKid);
            }
            navigate(`/autocadastro/crianca/0`);
        } else {
            setCurrentKid(formData.kids[Number(kidIndex)-1]);
            navigate(`/autocadastro/crianca/${(Number(kidIndex) - 1)}`);
        }
    }

    return (
        <div>
            <div style={{textAlign: 'center'}}><h2>Ficha Cadastral - Criança</h2></div>

            <div style={{textAlign: 'center'}}> <img src={searchImg} alt="Logo"/></div>
            {!addNewKid && Number(kidIndex) > 0 && Number(kidIndex) === formData.kids.length ?
                (<div>
                    <div>
                        Adicionar mais uma criança?
                    </div>
                    <div>
                        <button onClick={handleAddButtonClick}>Sim</button>
                        <button onClick={handleContinue}>Não</button>
                    </div>

                </div>) : (<>
                    <div style={{marginTop: '10px', alignItems: 'center'}}>
                        <div style={{width: '300px'}}>Nome  Astrolover (criança):</div>
                        <input style={{width: '300px'}} ref={inputTextKidName} type="text" id="name" value={currentKid.name} name="name"
                               onChange={handleChange}/>
                    </div>
                    <div style={{marginTop: '10px', alignItems: 'center'}}>
                        <div style={{width: '320px'}}>Data de nascimento do  Astrolover (criança):</div>
                        <DatePicker
                            className="datepicker"
                            ref={inputTextKidBirthDate}
                            dateFormat="dd/MM/yyyy"
                            name="birthDate"
                            selected={currentKid.birthDate ? adjustDateToBrowserTimeZone(new Date(currentKid.birthDate)) : undefined}
                            onChange={handleBirthDateChange}
                            isClearable
                        />
                    </div>


                    <div style={{marginTop: '10px', alignItems: 'center'}}>
                        <button onClick={handleBackButtonClick}>Voltar</button>
                        {formData.kids.length > 1 && Number(kidIndex) < formData.kids.length && (<button className="remove-button" onClick={handleRemoveButtonClick}>Remover</button>)}
                        <button onClick={handleSaveButtonClick}>Continuar</button>
                    </div>
                </>)}


        </div>
    );
};

export default CustomerAutoFilling_Kids;
