function CentralizedDiv({children}) {
    return (

        <div className="centralized-div1">
            <div style={{textAlign: 'left'}}>
                {children}
            </div>
        </div>
    );
}

export default CentralizedDiv;