import React from 'react';

const ActionButtonsManagerView = (props) => {
    const {children} = props;

    return (
        <div style={{textAlign: 'right', marginTop:'5px'}}>
            <div style={{display: "flex"}}>
                <div style={{marginLeft: 'auto'}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ActionButtonsManagerView;
