import {getOnlyDate} from "../utils/utils";

export function isOver(birthDate, years) {
    const currentDate = new Date();
    const birthYear = birthDate.getFullYear();
    const currentYear = currentDate.getFullYear();
    const birthMonth = birthDate.getMonth() + 1; // Adding 1 because months are zero-based
    const currentMonth = currentDate.getMonth() + 1;

    const birthDay = birthDate.getDate();
    const currentDay = currentDate.getDate();

    let ageDifference = currentYear - birthYear;

    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        ageDifference--;
    }

    return ageDifference >= years;
}

export function isDateAfterCurrent(dateToCheck) {
    const currentDate = getOnlyDate(new Date());
    return dateToCheck > currentDate;
}

export const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
};