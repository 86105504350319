import React from 'react';
import plusImg from "../img/icons8-plus-16.png"


const PlusButton = ({ onClick }) => {
  return (
    <button className="add-button" onClick={onClick}>
      <img src={plusImg} alt="Search" />
    </button>
  );
};

export default PlusButton;
