import React, {useEffect, useRef, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import ProductSelect from "../product/ProductSelect";
import CustomerSelect from "../customer/CustomerSelect";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {
    getProductAvailabilityFilterByQuantity,
    getProductAvailabilityPerDate,
    getProductById,
    getProductRentalPriceByProductId, getRentalById
} from "../../utils/requestUtils";
import {
    adjustDateToBrowserTimeZone,
    calculateDaysDifference,
    getOnlyDate
} from "../../utils/utils";
import PaymentMethodValue from "../paymentMehod/PaymentMethodValueSelect";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import moment from "moment";
import SearchButton from "../../core/SearchButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import BackButton from "../common/BackButton";
import ConfirmButton from "../common/ConfirmButton";
import PlusButton from "../../core/PlusButton";
import Cookies from "js-cookie";
import AddressSelect from "../customer/AddressSelect";

const apiUrl = Config.apiUrl;
const RentalManager = (props) => {
    const {id, rentalItemProp, onBack, onSave} = props;
    const [rental, setRental] = useState(undefined);

    const [showPaymentMethodValueSelect, setShowPaymentMethodValueSelect] = useState(false);

    const [productRentalPriceMap, setProductRentalPriceMap] = useState(new Map());

    const [indexRentalItem, setIndexRentalItem] = useState(-1);
    const [showCustomerSelect, setShowCustomerSelect] = useState(false);
    const [showProductSelect, setShowProductSelect] = useState(false);
    const [changeAddressVisible, setChangeAddressVisible] = useState(false);
    const [, setLoading] = useRecoilState(loading);

    const newEntity = {
        id: undefined,
        customer: undefined,
        rentalDate: getOnlyDate(new Date()),
        rentalItems: [],
        paymentMethods: [],
        receivables: [],
        shippingCost: 0,
        totalValue: 0,
        details: '',
    };


    const newRentalItem = {
        product: {id: undefined, name: ''},
        quantity: 1,
        rentalDays: 0,
        value: 0,
        total: 0,
        rentalDate: getOnlyDate(new Date()),
        returnDate: getOnlyDate(new Date())
    };
    const [formData, setFormData] = useState(newEntity);

    async function fullInputsWithRentalItemProp(rentalItemProp) {
        if (rentalItemProp.rentalId) {
            const rental = await getRentalById(rentalItemProp.rentalId);
            if (rental) {
                await selectCustomer(rental.customer.id);
            }

            const rentalItems = [];

            const filteredRentalItems = rental.rentalItems.filter(ri => rentalItemProp.rentalProductsId.find(rip => rip === ri.product.id))

            for (let i = 0; i < filteredRentalItems.length; i++) {

                const item = filteredRentalItems[i];

                const product = await getProductById(item.product.id);
                const rentalDate = item.returnDate;

                const newRentalDate = new Date(rentalItemProp.date);
                newRentalDate.setDate(newRentalDate.getDate() + 1);
                const originalRentalDays = calculateDaysDifference(adjustDateToBrowserTimeZone(new Date(item.rentalDate)), adjustDateToBrowserTimeZone(new Date(item.returnDate)));
                const newRentalDays = calculateDaysDifference(adjustDateToBrowserTimeZone(newRentalDate), adjustDateToBrowserTimeZone(new Date(item.returnDate)));

                const newReturnDate = new Date(rentalDate);
                newReturnDate.setDate(newReturnDate.getDate() + originalRentalDays);

                const productRentalPrices = await getProductRentalPriceByProductId(product.id);
                productRentalPriceMap.set(product.id, productRentalPrices);
                setProductRentalPriceMap(productRentalPriceMap);

                const rentalPrice = productRentalPrices.find(r => r.days === Number(item.rentalDays));

                rentalItems.push({
                    product: product,
                    quantity: item.quantity,
                    rentalDays: item.rentalDays,
                    value: rentalPrice.price,
                    total: rentalPrice.price * item.quantity,
                    rentalDate: rentalDate,
                    returnDate: newReturnDate
                });


                const availabilities = await getProductAvailabilityPerDate(product.id, rentalItemProp.rentalId, newRentalDays, new Date(formData.rentalDate), newRentalDate, newReturnDate, item.quantity);
                if (availabilities.length === 0) {
                    alert("Produto indisponível: " + product.name);
                    return false;
                }

            }
            rentalItems.push(newRentalItem);
            await setRentalItems(rentalItems)

        } else {
            if (rentalItemProp.customerId) {
                await selectCustomer(rentalItemProp.customerId);
            }
            const product = await getProductById(rentalItemProp.productId);
            const rentalDate = adjustDateToBrowserTimeZone(new Date(rentalItemProp.date));

            const newReturnDate = new Date(rentalDate);
            newReturnDate.setDate(newReturnDate.getDate() + rentalItemProp.days);


            const productRentalPrices = await getProductRentalPriceByProductId(product.id);
            productRentalPriceMap.set(product.id, productRentalPrices);
            setProductRentalPriceMap(productRentalPriceMap);

            const rentalPrice = productRentalPrices.find(r => r.days === Number(rentalItemProp.days));

            const newRentalItemProp = {
                product: product,
                quantity: rentalItemProp.quantity,
                rentalDays: rentalItemProp.days,
                value: rentalPrice.price,
                total: rentalPrice.price * rentalItemProp.quantity,
                rentalDate: rentalDate,
                returnDate: newReturnDate
            };

            await setRentalItems([newRentalItemProp, newRentalItem])
            const availabilities = await getProductAvailabilityPerDate(product.id, 0, rentalItemProp.days, new Date(formData.rentalDate), rentalDate, newReturnDate, rentalItemProp.quantity);
            if (availabilities.length === 0) {
                alert("Produto indisponível: " + product.name);
                return false;
            }
        }
    }


    async function makeQuery() {
        if (id && !rental) {
            try {
                const url = apiUrl + '/api/rentals/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                setFormData(response);
                const productRentalPriceMap = new Map();
                for (let i = 0; i < response.rentalItems.length; i++) {
                    const productId = response.rentalItems[i].product.id;
                    const productRentalPrices = await getProductRentalPriceByProductId(productId);
                    productRentalPriceMap.set(productId, productRentalPrices);
                    response.rentalItems[i].rentalDate = adjustDateToBrowserTimeZone(new Date(response.rentalItems[i].rentalDate));
                    response.rentalItems[i].returnDate = adjustDateToBrowserTimeZone(new Date(response.rentalItems[i].returnDate));
                }
                setProductRentalPriceMap(productRentalPriceMap);
                setRentalItems([...response.rentalItems,
                    newRentalItem,
                ])
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            clearEntity();
        }

        if (rentalItemProp) {
            await fullInputsWithRentalItemProp(rentalItemProp);
        }

        setShowPaymentMethodValueSelect(false);
    }

    async function getCustomerById(id) {

        try {
            const url = apiUrl + '/api/customers/id=' + id;
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }

    useEffect(() => {
        if (formData.customer && formData.customer.highlightNote && formData.customer.note && formData.customer.note !== '') {
            alert('Observação do Cliente:\n' + formData.customer.note);
        }
    }, [formData.customer]);


    useEffect(() => {
        makeQuery();
    }, [id]);

    async function validate() {

        if (formData.customer === undefined || formData.customer.id === undefined) {
            alert(`Informe o cliente.`);
            return false;
        }

        if (rentalItems.length === 1) {
            alert(`Informe ao menos 1 item.`);
            return false;
        }


        if (rentalItems.find((r) => !r.product && !r.product.id && (r.rentalDays === undefined || r.rentalDays === 0))) {
            alert(`Informe o período.`);
            return false;
        }

        for (const rentalItem of rentalItems) {
            if (rentalItem.product && rentalItem.product.id) {
                const quantity = rentalItem.quantity ? rentalItem.quantity : 0;

                const productId = rentalItem.product.id;
                const startDate = new Date(rentalItem.rentalDate);
                const endDate = new Date(rentalItem.returnDate);
                const days = calculateDaysDifference(startDate, endDate);

                const availabilities = await getProductAvailabilityPerDate(productId, getRentalIds(), days, new Date(formData.rentalDate), startDate, endDate, quantity);
                if (availabilities.length === 0) {
                    if (!window.confirm("Produto indisponível: " + rentalItem.product.name + "\nDeseja realmente alugar para essa data?")) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    const onConfirm = async () => {
        if (await validate()) {
            if (formData.totalValue) {
                openPaymentMethodValueSelect();
            } else {
                if (window.confirm("O valor do aluguel está zerado. Deseja realmente confirmar?")) {
                    confirmRental([]);
                }
            }
        }
    };

    async function saveEntity(paymentMethodValues) {
        const userUUID = Cookies.get('userUUID');

        const rental = {

            id: formData.id,
            code: formData.code,
            customer: formData.customer,
            rentalItems: rentalItems.slice(0, -1),
            rentalDate: formData.rentalDate,
            shippingCost: formData.shippingCost ? formData.shippingCost : 0,
            details: formData.details,
            paymentMethods: paymentMethodValues,
            totalValue: formData.totalValue,
            address: formData.address,
            addressNumber: formData.addressNumber,
            addressComplement: formData.addressComplement,
            neighborhood: formData.neighborhood,
            cep: formData.cep,
            city: formData.city,
            state: formData.state,

        };
        const url = apiUrl + '/api/rentals';
        const response = await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid': userUUID,
        }, JSON.stringify(rental));
        return response.id;
    }

    function clearEntity() {
        setFormData(newEntity);
        setRentalItems([newRentalItem]);
    }

    const confirmRental = async (paymentMethodValues) => {
        try {
            setLoading(true);
            const rentalId = await saveEntity(paymentMethodValues);
            if (rentalItemProp?.rentalId) {
                const productIds = rentalItems.slice(0, -1).map(ri => ri.product.id).join(',');

                let url = apiUrl + '/api/rentals/finishRentalItems?id=' + rentalItemProp.rentalId + '&productIds=' + productIds;
                await makeRequest(url, 'PUT', {
                    'Content-Type': 'application/json',
                    'Useruuid': Cookies.get('userUUID')
                });

                url = apiUrl + '/api/rentals/deliverRentalItems?id=' + rentalId + '&productIds=' + productIds;
                await makeRequest(url, 'PUT', {
                    'Content-Type': 'application/json',
                    'Useruuid': Cookies.get('userUUID')
                });

            }
            onSave();
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function handleOnClickCustomer() {
        openCustomerSelect();
    }


    function handleOnClickProduct(index) {
        setIndexRentalItem(index);
        openProductSelect();
    }

    async function selectCustomer(id) {
        const customer = await getCustomerById(id);
        setFormData((prevData) => {
            const newFormData = {
                ...prevData,
                customer: customer
            };

            if (prevData.customer?.id !== customer?.id) {
                newFormData.address = customer.address;
                newFormData.addressNumber = customer.addressNumber;
                newFormData.addressComplement = customer.addressComplement;
                newFormData.neighborhood = customer.neighborhood;
                newFormData.cep = customer.cep;
                newFormData.city = customer.city;
                newFormData.state = customer.state;
            }

            return newFormData;
        });
    }

    const handleCustomerItemClick = async (id) => {
        await selectCustomer(id);
        closeCustomerSelect();

    };


    function getRentalIds() {
        return [formData.id, rentalItemProp?.rentalId].filter(value => value !== 0 && value !== undefined).join(',');
    }

    async function selectProduct(productId, indexRentalItem) {
        const product = await getProductById(productId);

        rentalItems[indexRentalItem].product.name = product.name;
        rentalItems[indexRentalItem].product.id = product.id;

        const productRentalPrices = await getProductRentalPriceByProductId(productId);
        productRentalPriceMap.set(productId, productRentalPrices);
        setProductRentalPriceMap(productRentalPriceMap);

        const rentalPrice = productRentalPrices ? productRentalPrices[0].price : 0;
        const rentalDays = productRentalPrices.length > 0 ? productRentalPrices[0].days : 0;
        await setRentalItems((prevItems) =>
            prevItems.map((item, i) => {
                    return i === indexRentalItem ? {
                        ...item,
                        value: item.quantity * (rentalPrice ? rentalPrice : 0),
                        rentalDays: rentalDays
                    } : item;
                }
            )
        );

        if (indexRentalItem === rentalItems.length - 1) {
            setRentalItems(rentalItems => [...rentalItems,
                {
                    product: {id: undefined, name: ''},
                    quantity: 1,
                    rentalDays: 0,
                    value: 0,
                    total: 0,
                    rentalDate: getOnlyDate(new Date()),
                    returnDate: getOnlyDate(new Date())
                },
            ]);
        }

        const quantity = rentalItems[indexRentalItem].quantity;



        const rentalDate = new Date(formData.rentalDate);
        rentalDate.setDate(rentalDate.getDate() + 1);
        const availabilities = await getProductAvailabilityFilterByQuantity(productId, getRentalIds(), rentalDate, rentalDays, quantity);
        if (availabilities.length > 0) {
            await handleRentalDateItemChange(indexRentalItem, indexRentalItem >= 1 ? rentalItems[indexRentalItem - 1].rentalDate : adjustDateToBrowserTimeZone(new Date(availabilities[0].startDate)), rentalDays);
        } else {
            alert("Produto indisponível: " + product.name);
            return;
        }
    }

    const handleProductItemClick = async (id) => {
        setLoading(true);
        closeProductSelect();
        await selectProduct(id, indexRentalItem);
        setLoading(false);

    };

    const [rentalItems, setRentalItems] = useState([
        newRentalItem,
    ]);

    useEffect(() => {
        let total = parseFloat(formData.shippingCost ? formData.shippingCost : 0);
        rentalItems.forEach(i => total += i.total);
        setFormData((prevData) => ({
            ...prevData,
            ['totalValue']: total
        }));

    }, [rentalItems, formData.shippingCost]);


    // Função para processar a exclusão do produto
    const removeItem = (index) => {
        const novaLista = [...rentalItems];
        novaLista.splice(index, 1);
        setRentalItems(novaLista);
    };


    async function handleRentalDateItemChange(index, date, rentalDays) {

        const differenceDays = calculateDaysDifference(getOnlyDate(new Date(formData.rentalDate)), getOnlyDate(new Date(date)));
        if (differenceDays < 0) {
            alert('Data do aluguel não pode ser anterior à data do lançamento.');
            return;
        }
        rentalDays = (rentalDays ? rentalDays : rentalItems[index].rentalDays);
        const newReturnDate = getOnlyDate(new Date(date));
        newReturnDate.setDate(newReturnDate.getDate() + rentalDays);

        setRentalItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    rentalDate:
                        getOnlyDate(new Date(date)),
                    returnDate: newReturnDate
                } : item
            )
        );

        calculateTotal(index);
        const quantity = rentalItems[index].quantity;

        const product = rentalItems[index].product;

        const availabilities = await getProductAvailabilityPerDate(product.id, getRentalIds(), rentalDays, new Date(formData.rentalDate), new Date(date), newReturnDate, quantity)
        if (availabilities.length === 0) {
            alert("Produto indisponível: " + product.name);
            return;
        }

    }

    async function handleReturnDateItemChange(index, returnDate) {

        const rentalDate = adjustDateToBrowserTimeZone(new Date(rentalItems[index].rentalDate));
        const differenceDays = calculateDaysDifference(getOnlyDate(rentalDate), getOnlyDate(new Date(returnDate)));
        if (differenceDays < 0) {
            alert('Data da devolução não pode ser anterior à data da entrega.');
            return;
        }


        const productId = rentalItems[index].product.id;
        const productRentalPrices = productRentalPriceMap.get(productId);
        const productRentalPrice = productRentalPrices.find((p) => p.days >= differenceDays);

        setRentalItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    rentalDays: productRentalPrice ? productRentalPrice.days : item.rentalDays,
                    value: productRentalPrice ? productRentalPrice.price : item.value,
                    total: productRentalPrice ? productRentalPrice.price * item.quantity : item.value,
                    returnDate: getOnlyDate(new Date(returnDate))
                } : item
            )
        );

        calculateTotal(index);
        const quantity = rentalItems[index].quantity;


        const availabilities = await getProductAvailabilityPerDate(productId, getRentalIds(), differenceDays, new Date(formData.rentalDate), new Date(rentalDate), returnDate, quantity)
        if (availabilities.length === 0) {
            alert('Produto indisponível.')
            return;
        }

    }

    async function handleQuantityChange(index, quantity) {
        await setRentalItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    quantity:
                    quantity
                } : item
            )
        );

        calculateTotal(index);


        if (quantity > 0) {
            const productId = rentalItems[index].product.id;
            const days = rentalItems[index].rentalDays;
            const rentalDate = new Date(formData.rentalDate);
            rentalDate.setDate(rentalDate.getDate() + 1);
            const availabilities = await getProductAvailabilityFilterByQuantity(productId, getRentalIds(), rentalDate, days, quantity);
            if (availabilities.length > 0) {
                setRentalItems((prevItems) =>
                    prevItems.map((item, i) =>
                        i === index ? {
                            ...item,
                            rentalDate: adjustDateToBrowserTimeZone(new Date(availabilities[0].startDate))
                        } : item
                    )
                );

            } else {
                alert('Produto indisponível.')
                return;
            }
        }

    }

    function handleValueChange(index, value) {
        setRentalItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    value:
                        value ? value : 0,
                } : item
            )
        );

        calculateTotal(index);

    }

    function calculateTotal(index) {

        setRentalItems((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    total:
                        (item.quantity ? item.quantity : 0) * (item.value ? item.value : 0),
                } : item
            )
        );
    }

    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['rentalDate']: date
        }));
    };

    const openProductSelect = () => {
        setShowProductSelect(true);
    };

    const closeProductSelect = () => {
        setShowProductSelect(false);
    };

    const openCustomerSelect = () => {
        setShowCustomerSelect(true);
    };

    const closeCustomerSelect = () => {
        setShowCustomerSelect(false);
    };

    const openPaymentMethodValueSelect = () => {
        setShowPaymentMethodValueSelect(true);
    };

    const closePaymentMethodValueSelect = () => {
        setShowPaymentMethodValueSelect(false);
    };

    async function handleRentalPriceSelected(index, value) {
        const rentalPrices = productRentalPriceMap.get(rentalItems[index].product.id);
        const rentalPrice = rentalPrices.find(r => r.days === Number(value));


        const productId = rentalItems[index].product.id;
        const quantity = rentalItems[index].quantity;
        const rentalDate = new Date(formData.rentalDate);
        rentalDate.setDate(rentalDate.getDate() + 1);
        const newReturnDate = new Date(formData.rentalDate);
        const days = value ? Number(value) : 0;
        newReturnDate.setDate(newReturnDate.getDate() + days);

        const availabilities = await getProductAvailabilityFilterByQuantity(productId, getRentalIds(), rentalDate, days, quantity);
        if (availabilities.length > 0) {
            setRentalItems((prevItems) =>
                prevItems.map((item, i) => {
                        if (i === index) {
                            const rentalDate = adjustDateToBrowserTimeZone(new Date(availabilities[0].startDate));
                            const newReturnDate = adjustDateToBrowserTimeZone(new Date(availabilities[0].startDate));
                            newReturnDate.setDate(newReturnDate.getDate() + days);
                            return {
                                ...item,
                                rentalDays: days,
                                value: rentalPrice ? rentalPrice.price : 0,
                                rentalDate: rentalDate,
                                returnDate: newReturnDate
                            };
                        } else {
                            return item
                        }
                    }
                )
            );

        } else {
            alert('Produto indisponível.')
            return;
        }

        calculateTotal(index);
    }

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const onChangeAddress = (formDataParam) => {

        formData.address = formDataParam.address;
        formData.addressNumber = formDataParam.addressNumber;
        formData.addressComplement = formDataParam.addressComplement;
        formData.neighborhood = formDataParam.neighborhood;
        formData.cep = formDataParam.cep;
        formData.city = formDataParam.city;
        formData.state = formDataParam.state;

        setFormData(formData);
        setChangeAddressVisible(false);
    }

    return (
        <div>
            {changeAddressVisible && <AddressSelect
                onConfirm={onChangeAddress}
                onClose={() => setChangeAddressVisible(false)}
                address={formData.address ? formData.address : formData.customer.address}
                addressNumber={formData.address ? formData.addressNumber : formData.customer.addressNumber}
                addressComplement={formData.address ? formData.addressComplement : formData.customer.addressComplement}
                neighborhood={formData.address ? formData.neighborhood : formData.customer.neighborhood}
                cep={formData.address ? formData.cep : formData.customer.cep}
                city={formData.address ? formData.city : formData.customer.city}
                state={formData.address ? formData.state : formData.customer.state}

            />}
            {showPaymentMethodValueSelect && (
                <PaymentMethodValue onItemClick={confirmRental} onClose={closePaymentMethodValueSelect}
                                    paymentMethods={formData.paymentMethods}
                                    totalValue={formData.totalValue} onConfirm={(paymentMethodValues) => {
                    closePaymentMethodValueSelect();
                    confirmRental(paymentMethodValues);
                }}/>)}
            {showCustomerSelect && (
                <CustomerSelect onItemClick={handleCustomerItemClick} onClose={closeCustomerSelect}/>)}
            {showProductSelect && (<ProductSelect onItemClick={handleProductItemClick} onClose={closeProductSelect}/>)}
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Novo'} Aluguel {formData.id > 0 && '- '+formData.code}</div>
            {/*{formData.id && (*/}
            {/*    <div style={{marginTop: '5px', alignItems: 'center'}}>*/}
            {/*        <div style={{width: '180px'}}>Código:</div>*/}
            {/*        <input type="text" style={{backgroundColor: '#CCCCCC'}} value={formData.id} disabled={true}/>*/}
            {/*    </div>)}*/}

            <div style={{display: "flex"}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Cliente:</div>
                    <SearchButton onClick={handleOnClickCustomer}/>
                    <input type="text" disabled={true} value={formData.customer ? formData.customer.name : ''}
                           style={{width: '300px'}}/>
                </div>
                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>Data:</div>
                    <DatePicker
                        className="datepicker"
                        selected={adjustDateToBrowserTimeZone(new Date(formData.rentalDate))}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
                {formData.customer &&
                    <>
                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div>Endereço:</div>
                            <div style={{
                                maxWidth: '425px',
                                height: '37.6px',
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                overflowWrap: "break-word",
                                fontSize: '14px'
                            }}>
                                {formData.address !== undefined && (formData.address +
                                    (formData.addressNumber !== null && formData.addressNumber > 0 ?
                                        (', ' + formData.addressNumber) : '') +
                                    (formData.addressComplement !== null && formData.addressComplement !== undefined && formData.addressComplement !== '' ? ' - ' + formData.addressComplement : '') +
                                    ' - ' + formData.neighborhood)}
                            </div>
                        </div>
                        <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                            <div style={{height: '17.6px'}}/>
                            <div style={{height: '37.6px', display: "flex", alignItems: "center"}}>
                                <button onClick={() => setChangeAddressVisible(true)}>Endereço</button>
                            </div>
                        </div>
                    </>
                }
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>


                <table style={{width: '1000px', height: '351px'}}>

                    <colgroup>
                        <col style={{width: '450px'}}/>
                        <col style={{width: '50px'}}/>
                        <col style={{width: '50px'}}/>
                        <col style={{width: '50px'}}/>
                        <col style={{width: '50px'}}/>
                        <col style={{width: '50px'}}/>
                        <col style={{width: '50px'}}/>
                        <col style={{width: '50px'}}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th>Período</th>
                        <th>Entrega</th>
                        <th>Devolução</th>
                        <th>Valor (R$)</th>
                        <th>Total (R$)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody style={{overflowY: 'auto'}}>
                    {rentalItems.map((item, index) => (
                        <>
                            {item.product && item.product.id && (
                                <tr key={item.id}>
                                    <td>
                                        <div>
                                            {index < rentalItems.length - 1 ?
                                                <SearchButton onClick={() => handleOnClickProduct(index)}/> :
                                                <PlusButton onClick={() => handleOnClickProduct(index)}/>
                                            }
                                            {index < rentalItems.length - 1 && item.product && item.product.id ? `${item.product.name}` : ''}
                                        </div>
                                    </td>
                                    {index < rentalItems.length - 1 && (
                                        <>
                                            <td>
                                                <input style={{width: '70px'}} key={item.id} type="number" step="1"
                                                       placeholder=""
                                                       value={item.quantity}
                                                       onChange={(e) => handleQuantityChange(index, parseFloat(e.target.value))}/>
                                            </td>
                                            <td>
                                                <select style={{width: '100px'}} value={item.rentalDays}
                                                        onChange={(e) => handleRentalPriceSelected(index, e.target.value)}>
                                                    <option value="">Selecione um período</option>
                                                    {productRentalPriceMap.get(item.product.id) && productRentalPriceMap.get(item.product.id).map((rentalPrice, index) => (
                                                        <option key={index} value={rentalPrice.days}>
                                                            {rentalPrice.days} dias
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td>
                                                <DatePicker
                                                    className="datepicker"
                                                    selected={adjustDateToBrowserTimeZone(new Date(item.rentalDate))}
                                                    onChange={(date) => handleRentalDateItemChange(index, date)}
                                                    dateFormat="dd/MM/yyyy"
                                                />

                                            </td>
                                            <td>
                                                <DatePicker
                                                    className="datepicker"
                                                    selected={adjustDateToBrowserTimeZone(new Date(item.returnDate))}
                                                    onChange={(date) => handleReturnDateItemChange(index, date)}
                                                    dateFormat="dd/MM/yyyy"

                                                />
                                            </td>
                                            <td>
                                                <input style={{width: '100px', textAlign: 'right'}} key={item.id}
                                                       type="number" step="0.01"
                                                       placeholder="0.00" value={item.value}
                                                       onChange={(e) => handleValueChange(index, parseFloat(e.target.value))}/>
                                            </td>
                                            <td>
                                                <div style={{width: '70px', textAlign: 'right'}}>
                                                    {item.total.toLocaleString('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </div>
                                            </td>
                                            <td>
                                                {index < rentalItems.length - 1 && (
                                                    <button className="remove-button"
                                                            onClick={() => removeItem(index)}>Excluir</button>)}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            )
                            }
                        </>
                    ))}
                    <PlusButton onClick={() => handleOnClickProduct(rentalItems.length - 1)}/>
                    </tbody>
                </table>

            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div style={{marginTop: '5px', display: 'flex', flexDirection: 'column'}}>
                    <div>Frete:</div>
                    <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                           min={0}
                           value={formData.shippingCost}
                           name="shippingCost"
                           onKeyDown={(e) => {
                               if(e.key === '-') {
                                   e.preventDefault();
                               }
                           }}
                           onChange={(e) => {
                               const value = e.target.value ? parseFloat(e.target.value) : 0;
                               if(value >= 0) {
                                   handleChange(e);
                               }
                           }}/>
                </div>

                <div style={{
                    marginTop: '5px',
                    marginLeft: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexGrow: 1
                }}>
                    <div style={{width: '100%'}}>Observação:</div>
                    <input type="text" style={{width: '100%'}} value={formData.details} name="details"
                           onChange={handleChange}/>
                </div>

                <div style={{
                    marginLeft: '5px',
                    marginTop: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div style={{height: '17.6px'}}/>
                    <div className="total-label" style={{height: '37.6px', display: 'flex', alignItems: "center"}}>
                        Valor Total: R$ {formData.totalValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                    </div>
                </div>
            </div>


            <ActionButtonsManagerView>
                <BackButton onClick={onBack}/>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>

        </div>
    );
};

export default RentalManager;
