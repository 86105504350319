import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from '../../utils/httpRequest';
import '../../css/Select.css';
import ModalDiv from "../../ModalDiv";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie"; // Import a CSS file for styling

const apiUrl = Config.apiUrl;

const CustomerSelect = (props) => {
    const {onItemClick, onClose} = props;
    const [customers, setCustomers] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loading);


    async function makeQuery(query) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/customers/query=' + (query && query !== '' ? query : '');
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setCustomers(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        makeQuery('');
    }, []);

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };

    const handleQuery = async (e) => {
        await makeQuery(queryString);
    };

    return (
        <ModalDiv closeModal={onClose} style_={{width: '500px'}}>
            <div className="header-label">Consulta de Clientes</div>
            <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
            <table style={{width: '495px', height: '480px'}}>

                <colgroup>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '500px'}}/>
                </colgroup>

                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Nome
                    </th>
                </tr>

                {customers.map((customer, index) => (

                    <tr key={index}
                        className="select-list-item"
                        onClick={() => {
                            onItemClick(customer.id);
                        }}
                        onMouseEnter={() => {
                            // Handle hover effect (change background color)
                        }}
                        onMouseLeave={() => {
                            // Handle hover effect (restore background color)
                        }}>
                        <td>
                            <div>
                                {customer.code}
                            </div>
                        </td>
                        <td>
                            <div>
                                {customer.name}
                            </div>
                        </td>
                    </tr>

                ))}
                {customers.length === 0 && <div className="select-list-empty">Nenhum item encontrado.</div>}


            </table>
        </ModalDiv>
    );
};

export default CustomerSelect;
