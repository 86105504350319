import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const LineChart = ({ labels, dataValues, secondaryDataValues, currencyformat, label1, label2 }) => {
    const data = {
        labels: labels,
        datasets: [
            {
                label: label1,
                data: dataValues,
                borderColor: 'rgba(54, 162, 235, 0.6)',
                backgroundColor: 'rgba(54, 162, 235, 0.1)',
                fill: true, // Preencher abaixo da linha
                tension: 0.4, // Suaviza as linhas
                pointBackgroundColor: 'rgba(54, 162, 235, 1)',
                pointBorderColor: 'rgba(54, 162, 235, 1)',
                pointRadius: 5,
                pointHoverRadius: 7,
            },
            {
                label: label2,
                data: secondaryDataValues,
                borderColor: 'rgba(255, 99, 132, 0.6)', // Cor diferente para a segunda linha
                backgroundColor: 'rgba(255, 99, 132, 0.1)',
                fill: true,
                tension: 0.4,
                pointBackgroundColor: 'rgba(255, 99, 132, 1)',
                pointBorderColor: 'rgba(255, 99, 132, 1)',
                pointRadius: 0,
                pointHoverRadius: 7,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                display: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: 'black',
                },
            },
            x: {
                display: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: 'black',
                },
            },
        },
        plugins: {
            legend: {
                display: true, // Exibe a legenda para diferenciar as linhas
            },
            tooltip: {
                enabled: true,
                mode: 'nearest',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        if (currencyformat) {
                            return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                        }
                        return value;
                    }
                }
            },
            datalabels: {
                display: false,
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default LineChart;
