class HttpError extends Error {
    constructor(statusCode, message) {
        super(message);
        this.statusCode = statusCode;
        this.name = 'HttpError';
    }
}

export function makeRequest(url, method, headers, body) {
    const options = {
        method: method,
        headers: headers,
        body: body
    };

    return fetch(url, options)
        .then(response => {

            if (response.status === 403) {
                window.location.href = '/login';
            }
            if (!response.ok) {
                throw new HttpError(404, `Request error: ${response.statusText}`);
            }

            if(method === 'DELETE') {
                return response;
            }
            return response.json();
        })
        .catch(error => {
            console.error('Request error:', error);
            throw error;
        });
}