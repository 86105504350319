import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {formatDateString, formatDateToAmericanFormat} from "../../utils/utils";
import Config from "../../core/config";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import CustomerSelect from "../customer/CustomerSelect";
import {downloadContract, downloadDeliveryStatement, getCustomerById, getProductById} from "../../utils/requestUtils";
import SearchButton from "../../core/SearchButton";
import ProductSelect from "../product/ProductSelect";
import Cookies from "js-cookie";
import WhatsAppButton from "../../core/WhatsAppButton";
import {saveAs} from 'file-saver';
const apiUrl = Config.apiUrl;
const RentalList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    let [rentals, setRentals] = useState([]);
    const [queryString, setQueryString] = useState('');
    const [customer, setCustomer] = useState(undefined);
    const [product, setProduct] = useState(undefined);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [status, setStatus] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [shippingCost, setShippingCost] = useState(0);
    const [, setLoading] = useRecoilState(loading);
    const [showCustomerSelect, setShowCustomerSelect] = useState(false);
    const [showProductSelect, setShowProductSelect] = useState(false);

    const [pdfData, setPdfData] = useState(null);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/rentals/queryByDateAndStatus?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${status}&customerId=${customer ? customer.id : 0}&productId=${product ? product.id : 0}`;
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setRentals(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery('');
    }, []);
    //
    // useEffect(() => {
    //     makeQuery('');
    // }, [customer, product, status]);

    useEffect(() => {
        setTotalValue(rentals.reduce((acc, obj) => acc + obj.totalValue - obj.shippingCost, 0));
        setShippingCost(rentals.reduce((acc, obj) => acc + obj.shippingCost, 0));
    }, [rentals]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    async function removeRental(id) {
        try {
            const url = apiUrl + '/api/rentals/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleInputChange = (e) => {
        setQueryString(e.target.value);
    };

    const handleQuery = async () => {
        await makeQuery();
    };

    const generateExcelFile = async () => {
        try {
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/rentals/excelByDate?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}`;

            const response = await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
            if (response.ok) {
                const data = await response.blob();
                saveAs(data, `relatorio-lancamentos-${formatDateToAmericanFormat(newStartDate)}-${formatDateToAmericanFormat(newEndDate)}.xlsx`);
            } else {
                console.error('Erro ao gerar o arquivo Excel:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao fazer a solicitação para gerar o arquivo Excel:', error);
        }
    };


    async function selectCustomer(id) {
        const customer = await getCustomerById(id);
        setCustomer(customer);
    }

    const handleCustomerItemClick = async (id) => {
        await selectCustomer(id);
        closeCustomerSelect();

    };

    const openCustomerSelect = () => {
        setShowCustomerSelect(true);
    };


    const closeCustomerSelect = () => {
        setShowCustomerSelect(false);
    };

    function handleOnClickCustomer() {
        openCustomerSelect();
    }

    async function selectProduct(id) {
        const product = await getProductById(id);
        setProduct(product);
    }

    const handleProductItemClick = async (id) => {
        await selectProduct(id);
        closeProductSelect();

    };

    const openProductSelect = () => {
        setShowProductSelect(true);
    };


    const closeProductSelect = () => {
        setShowProductSelect(false);
    };

    function handleOnClickProduct() {
        openProductSelect();
    }

    return (
        <>
            {showCustomerSelect && (
                <CustomerSelect onItemClick={handleCustomerItemClick} onClose={closeCustomerSelect}/>)}
            {showProductSelect && (
                <ProductSelect onItemClick={handleProductItemClick} onClose={closeProductSelect}/>)}
            <div className="header-label">Consulta de Aluguéis</div>
            <div style={{display: "flex"}}>

                <div style={{width: '100%'}}>
                    <div>Cliente:</div>
                    <div style={{marginTop: '5px', alignItems: 'center', display: 'flex'}}>
                        <SearchButton onClick={handleOnClickCustomer}/>
                        <input type="text" disabled={true} value={customer ? customer.name : ''} style={{flexGrow: 1}}/>
                        {customer && <button className="clean-button" onClick={() => setCustomer(undefined)}>Limpar</button>}
                    </div>
                </div>

                <div style={{width: '100%'}}>
                    <div>Produto:</div>
                    <div style={{marginTop: '5px', alignItems: 'center', display: 'flex'}}>
                        <SearchButton onClick={handleOnClickProduct}/>
                        <input type="text" disabled={true} value={product ? product.name : ''} style={{flexGrow: 1}}/>
                        {product && <button className="clean-button" onClick={() => setProduct(undefined)}>Limpar</button>}
                    </div>
                </div>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        Data de Lançamento:
                        <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>

                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            <select value={status} onChange={(e) => setStatus(parseInt(e.target.value))}>
                                <option key={-1} value={-1}>Todos</option>
                                <option key={0} value={0}>Pendente</option>
                                <option key={1} value={1}>Baixado</option>
                            </select>

                            <button onClick={handleQuery}>Consultar</button>
                            <button onClick={generateExcelFile} disabled={!startDate || !endDate}>Gerar Excel</button>
                        </div>
                    </div>
                </div>
                <button onClick={onNewButtonClick}>Novo</button>
            </div>
            <table style={{width: '800px', height: '380px'}}>
                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Nome
                    </th>
                    <th/>
                    <th>
                        Data
                    </th>
                    <th>
                        Total
                    </th>
                    <th/>
                    <th/>
                    <th/>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {rentals.map((rental, index) => (


                    <tr>
                        <td>
                            <div key={index}>{rental.code}</div>
                        </td>
                        <td>
                            <div key={index}>{rental.customer.name}</div>
                        </td>
                        <td>
                            <WhatsAppButton phoneNumber={rental.customer.phone}/>
                        </td>
                        <td>
                            <div key={index}>{formatDateString(rental.rentalDate)}</div>
                        </td>

                        <td>
                            <div key={index} style={{textAlign: 'right'}}>{rental.totalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                        <td>
                            <button onClick={async () => {
                                await downloadContract(rental.id, rental.code);
                            }}>Contrato
                            </button>
                        </td>
                        <td>
                            <button onClick={async () => {
                                await downloadDeliveryStatement(rental.id, rental.code);
                            }}>Entrega
                            </button>
                        </td>
                        <td>
                            <button onClick={async () => {
                                await onEditButtonClick(rental.id);
                            }}>Editar
                            </button>
                        </td>
                        <td>
                            <button className="remove-button" onClick={async () => {
                                // eslint-disable-next-line no-restricted-globals
                                if (window.confirm('Deseja realmente excluir?')) {
                                    await removeRental(rental.id);
                                }
                            }}>Excluir
                            </button>
                        </td>

                    </tr>
                ))}
                </tbody>
                {rentals.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
            <div style={{marginTop: '5px', alignItems: 'center', display: 'flex'}}>
                <div className="total-label" >Aluguéis: {rentals.length}
                </div>
                <div className="total-label" style={{marginLeft: '10px'}}>Valor: R$ {totalValue.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}
                </div>
                <div className="total-label" style={{marginLeft: '10px'}}>Fretes:
                    R$ {shippingCost.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </div>
                <div className="total-label" style={{marginLeft: '10px'}}>Total:
                    R$ {(shippingCost + totalValue).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </div>
            </div>
        </>
    );
};

export default RentalList;
