import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Config from "../../core/config";
import {validateCPF} from "../../core/cpf";
import {validateCNPJ} from "../../core/cnpj";
import {searchCEP} from "../../core/Cep";
import {brazilianStates} from "../../utils/utils";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import BackButton from "../common/BackButton";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;

const SupplierManager = (props) => {
    const {id, onSave, onBack} = props;
    const [, setLoading] = useRecoilState(loading);

    const newEntity = {
        id: undefined,
        name: '',
        fantasyName: '',
        address: '',
        addressNumber: '',
        addressComplement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        cpfCnpj: '',
        inscricaoEstadual: ''
    };
    const [formData, setFormData] = useState(newEntity);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/suppliers/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
                response.birthDate = new Date(response.birthDate);
                response.kidBirthDate = new Date(response.kidBirthDate);
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        makeQuery();
    }, [id]);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {

        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                onSave();
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function validateInputs() {

        if (!formData.name || formData.name === '') {
            alert('Preencha o campo Razão Social.');
            return false;
        }

        if (!formData.fantasyName || formData.fantasyName === '') {
            alert('Preencha o campo nome.');
            return false;
        }

        if (!formData.address || formData.address === '') {
            alert('Preencha o campo endereço.');
            return false;
        }

        if (!formData.neighborhood || formData.neighborhood === '') {
            alert('Preencha o campo bairro.');
            return false;
        }

        if (!formData.cep || formData.cep === '') {
            alert('Preencha o campo cep.');
            return false;
        }


        if (!formData.city || formData.city === '') {
            alert('Preencha o campo cidade.');
            return false;
        }

        if (!formData.state || formData.state === '') {
            alert('Preencha o campo estado.');
            return false;
        }

        if (!formData.phone || formData.phone === '') {
            alert('Preencha o campo telefone.');
            return false;
        }

        if (!validateCNPJ(formData.cpfCnpj) && !validateCPF(formData.cpfCnpj)) {
            alert('CPF/CNPJ inválido.');
            return false;
        }

        if (!formData.inscricaoEstadual || formData.inscricaoEstadual === '') {
            alert('Preencha o campo Inscricao Estadual.');
            return false;
        }

        return true;
    }

    async function saveEntity() {
        const url = apiUrl + '/api/suppliers';
        await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid':Cookies.get('userUUID')
        }, JSON.stringify(formData));
    }

    async function searchCepAndFillAddressInputs() {

        let result = undefined;
        setLoading(true);
        try {
            result = await searchCEP(formData.cep);
        } finally {
            setLoading(false);
        }

        if (result != null) {
            setFormData((prevData) => ({
                ...prevData,
                address: result.street,
                neighborhood: result.neighborhood,
                city: result.city,
                state: result.state,
            }));
        } else {
            alert('CEP não encontrado.');
        }

    }

    function handleStateChange(idState) {
        setFormData((prevData) => ({
            ...prevData,
            state: idState,
        }));
    }

    return (
        <div>
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Fornecedor</div>

            {/*{formData.id && (*/}
            {/*    <div style={{marginTop: '5px', alignItems: 'center'}}>*/}
            {/*        <div style={{width: '180px'}}>Código:</div>*/}
            {/*        <input type="text" style={{backgroundColor: '#CCCCCC'}} value={formData.id} disabled={true}/>*/}
            {/*    </div>)}*/}

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Razão Social:</div>
                    <input type="text" style={{width: '300px'}} value={formData.name} name="name"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Nome Fantasia:</div>
                    <input type="text" style={{width: '300px'}} value={formData.fantasyName} name="fantasyName"
                           onChange={handleChange}/>
                </div>


                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>CPF/CNPJ:</div>
                    <input style={{width: '135px'}} type="text" id="cpf" value={formData.cpfCnpj} name="cpfCnpj"
                           maxLength="14"
                           onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div>Inscrição Estadual:</div>
                    <input style={{width: '140px'}} type="text" id="cpf" value={formData.inscricaoEstadual}
                           name="inscricaoEstadual"
                           maxLength="14"
                           onChange={handleChange}/>
                </div>
            </div>


            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '80px'}}>CEP:</div>
                    <div>
                        <input style={{width: '80px'}} type="text" id="cep" value={formData.cep} name="cep"
                               onChange={handleChange}/>
                        <button onClick={searchCepAndFillAddressInputs}>Buscar CEP</button>
                    </div>
                </div>
                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Endereço:</div>
                    <input type="text" style={{width: '297px'}} value={formData.address} name="address"
                           onChange={handleChange} autoComplete={false}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '70px'}}>Número:</div>
                    <input type="number" step="1" placeholder="0" style={{width: '70px'}}
                           value={formData.addressNumber} name="addressNumber"
                           onChange={handleChange} autoComplete={false}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Complemento:</div>
                    <input type="text" style={{width: '310px'}} value={formData.addressComplement}
                           name="addressComplement"
                           onChange={handleChange} autoComplete={false}/>
                </div>

            </div>
            <div style={{display: 'flex'}}>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Bairro:</div>
                    <input type="text" style={{width: '400px'}} id="neighborhood" value={formData.neighborhood}
                           name="neighborhood" onChange={handleChange}/>
                </div>


                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Cidade:</div>
                    <input type="text" style={{width: '290px'}} id="city" value={formData.city}
                           name="city" onChange={handleChange}/>
                </div>


                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Estado:</div>
                    <select value={formData.state} onChange={(e) => handleStateChange(e.target.value)}>
                        <option value="">Selecione...</option>
                        {brazilianStates.map((estado) => (
                            <option key={estado.sigla} value={estado.sigla}>
                                {estado.sigla} - {estado.nome}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Telefone:</div>
                    <input type="text" id="phone" value={formData.phone} name="phone" onChange={handleChange}/>
                </div>

                <div style={{marginTop: '5px', marginLeft: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Email:</div>
                    <input type="text" style={{width: '300px'}} id="email" value={formData.email} name="email"
                           onChange={handleChange}/>
                </div>
            </div>

            <ActionButtonsManagerView>
                <BackButton onClick={onBack}/>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>


        </div>
    );
};

export default SupplierManager;
