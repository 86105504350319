import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone, calculateDaysDifference,
    formatDateToAmericanFormat,
    formatDateToString, getOnlyDate
} from "../../utils/utils";
import {getPaymentById} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const PaymentList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    const [payments, setPayments] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const initialEndDate = undefined;
    const [endDate, setEndDate] = useState(initialEndDate);
    const [status, setStatus] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [, setLoading] = useRecoilState(loading);


    async function makeQuery() {
        try {
            setLoading(true);
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);

            const url = `${apiUrl}/api/payments/queryByPaymentDate?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${status}`;
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setPayments(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(payments.reduce((acc, obj) => acc + obj.value, 0));
    }, [payments]);

    async function removePayment(id) {
        try {
            const url = apiUrl + '/api/payments/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
            await makeQuery();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const onPayClick = async (id) => {
        const payment = await getPaymentById(id);
        if (!window.confirm(`Deseja realmente ${!payment.paymentDate ? "Baixar" : "Estornar"}?`)) {
            return;
        }
        if (payment.paymentDate) {
            payment.paymentDate = undefined;
            payment.status = 0;
        } else {
            payment.paymentDate = getOnlyDate(new Date());
            payment.status = 1;
        }

        const url = apiUrl + '/api/payments';
        await makeRequest(url, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid':Cookies.get('userUUID')
        }, JSON.stringify(payment));

        await makeQuery();
    };

    function getBackgroundColor(payment) {
        const currentDate = getOnlyDate(new Date());
        const dueDate = getOnlyDate(adjustDateToBrowserTimeZone(new Date(payment.dueDate)));
        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = 'var(--normal-color)';
        if (payment.status === 0) {
            if (dueDate < currentDate)
                return errorColor;
            if (calculateDaysDifference(dueDate, currentDate) === 0) {
                return warningColor;
            }
        }
        return normalColor;
    }

    return (
        <>
            <div className="header-label">Consulta de Pagamentos</div>

            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>

                    Data de Vencimento:
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <DatePicker
                            className="datepicker"
                            selected={startDate}
                            onChange={handleStartDateChange}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                        />

                        até
                        <DatePicker
                            className="datepicker"
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                        />


                        <select value={status} onChange={(e) => setStatus(parseInt(e.target.value))}>
                            <option key={-1} value={-1}>Todos</option>
                            <option key={0} value={0}>Pendente</option>
                            <option key={1} value={1}>Pago</option>
                        </select>

                        <button onClick={handleQuery}>Consultar</button>
                    </div>
                </div>
                <button onClick={onNewButtonClick}>Novo</button>
            </div>

            <table style={{width: '1000px', height: '420px'}}>

                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Cliente
                    </th>
                    <th>
                        Descrição
                    </th>
                    <th>
                        Lançamento
                    </th>
                    <th>
                        Vencimento
                    </th>
                    <th>
                        Pagamento
                    </th>
                    <th>
                        Valor
                    </th>
                    <th/>
                    <th/>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {payments.map((payment, index) => (


                    <tr style={{backgroundColor: getBackgroundColor(payment)}}>
                        <td>
                            <div key={index}>{payment.code}</div>
                        </td>
                        <td>
                            <div key={index}>{payment.supplier.name}</div>
                        </td>
                        <td>
                            <div key={index}>{payment.description}</div>
                        </td>
                        <td>
                            <div key={index}>{formatDateToString(new Date(payment.date))}</div>
                        </td>
                        <td>
                            <div key={index}>{formatDateToString(new Date(payment.dueDate))}</div>
                        </td>
                        <td>
                            <div
                                key={index}>{payment.paymentDate ? formatDateToString(new Date(payment.paymentDate)) : "Pendente"}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{payment.value.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                        <td>
                            <button onClick={async () => {
                                await onEditButtonClick(payment.id);
                            }}>Editar
                            </button>
                        </td>
                        <td>
                            <button className="finish-button" onClick={async () => {
                                await onPayClick(payment.id);
                            }}>
                                {!payment.paymentDate ? "Baixar" : "Estornar"}
                            </button>
                        </td>
                        <td>
                            <button className="remove-button" onClick={async () => {
                                // eslint-disable-next-line no-restricted-globals
                                if (window.confirm('Deseja realmente excluir?')) {
                                    await removePayment(payment.id);
                                }
                            }}>Excluir
                            </button>
                        </td>

                    </tr>
                ))}
                </tbody>
                {payments.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</div>
            </div>

        </>
    );
};

export default PaymentList;
