import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {formatDateString, formatDateToAmericanFormat} from "../../utils/utils";
import Config from "../../core/config";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const KidsBirthday = () => {
    let [customerEmailList, setCustomerEmailList] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery() {
        try {
            setLoading(true);
            const url = `${apiUrl}/api/customers/customer-email`;
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setCustomerEmailList(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery();
    }, []);

    function copyToClipboard() {
        let clipboardText = customerEmailList.map(customer => `${customer.customerName.toUpperCase()}\t${customer.customerEmail.toLowerCase()}`).join('\n');

        navigator.clipboard.writeText(clipboardText)
            .then(() => {
                alert('Lista de e-mails copiada para a área de transferência.');
            })
            .catch(err => {
                console.error('Erro ao copiar para a área de transferência: ', err);
            });
    }

    return (
        <>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div>
                    <div className="header-label">Lista de Emails</div>
                </div>
                <button onClick={copyToClipboard}>Copiar</button>
            </div>

            <table style={{width: '500px', height: '380px'}}>
                <colgroup>
                    <col style={{width: '60px'}}/>
                    <col style={{width: '220px'}}/>
                    <col style={{width: '220px'}}/>
                </colgroup>
                <tr>
                    <th>
                        Nome do Cliente
                    </th>
                    <th>
                        e-mail
                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {customerEmailList.map((customerEmail, index) => (
                    <tr>
                        <td>
                            <div key={index}>{customerEmail.customerName}</div>
                        </td>
                        <td>
                            <div key={index}>{customerEmail.customerEmail.toLowerCase()}</div>
                        </td>
                    </tr>
                ))}
                </tbody>
                {customerEmailList.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
        </>
    );
};

export default KidsBirthday;
