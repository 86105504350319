import React from 'react';
import searchImg from "../img/icons8-search-16.png"


const SearchButton = ({ onClick }) => {
  return (
    <button className="search-button" onClick={onClick}>
      <img src={searchImg} alt="Search" />
    </button>
  );
};

export default SearchButton;
