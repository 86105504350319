import React from 'react';
import searchImg from "../img/icons8-whatsapp-32.png"

const WhatsAppButton = ({phoneNumber}) => {
    const phoneOnlyNumbers = '55' + (phoneNumber !== undefined && phoneNumber != null ? phoneNumber.replace(/\D/g, "") : '');

    const handleWhatsAppClick = (e) => {
        e.preventDefault();
        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneOnlyNumbers}`;
        window.open(whatsappLink, '_blank');
    };

    return (
        <>
            {phoneOnlyNumbers !== '55' &&
                <a href="#" className="whatsapp-button" onClick={handleWhatsAppClick}>
                    <img src={searchImg} alt="Whats App"/>
                </a>
            }
        </>
    );
};

export default WhatsAppButton;
