import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const PaymentMethodList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    let [paymentMethods, setPaymentMethods] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/paymentMethods/query=' + (query && query !== '' ? (query) : '');
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setPaymentMethods(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        makeQuery('');
    }, []);

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };


    async function removePaymentMethod(id) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/paymentMethods/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="header-label">Consulta de Formas de Pagamento</div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
                <button onClick={onNewButtonClick}>Novo</button>
            </div>
            <table style={{width: '540px', height: '460px'}}>
                <colgroup>
                    <col style={{width: '100px'}}/>
                    <col style={{width: '440px'}}/>
                </colgroup>

                <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome</th>
                    <th/>
                    <th/>
                </tr>
                </thead>

                <tbody style={{overflowY: 'auto'}}>
                {paymentMethods.map((paymentMethod, index) => (
                    <tr key={index}>
                        <td>{paymentMethod.code}</td>
                        <td>{paymentMethod.name}</td>
                        <td>
                            <button onClick={async () => await onEditButtonClick(paymentMethod.id)}>Editar
                            </button>
                        </td>
                        <td>
                            <button
                                className="remove-button"
                                onClick={async () => {
                                    if (window.confirm('Deseja realmente excluir?')) {
                                        await removePaymentMethod(paymentMethod.id);
                                    }
                                }}
                            >
                                Excluir
                            </button>
                        </td>
                    </tr>
                ))}
                {paymentMethods.length === 0 && <tfoot>Nenhum item encontrado.</tfoot>}
                </tbody>
            </table>
        </>
    );
};

export default PaymentMethodList;
