// En un archivo como atoms.js
import { atom } from 'recoil';

export const loading = atom({
    key: 'atoms',
    default: false,
});

export const customerAutoFilling = atom({
    key: 'customerAutoFiliing',
    default: undefined,
});