import React from 'react';
const CloseButton = ({ onClose }) => {
    return (
        <button
            className="close-button"
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
            }}
            onClick={onClose}
        >
            x
        </button>
    );
};

export default CloseButton;
