export async function searchCEP(cep) {
    try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        const data = await response.json();

        if (data.erro) {
            console.error("CEP not found");
            return null;
        }

        const result = {
            street: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
        };

        return result;
    } catch (error) {
        console.error("Error in zip code search:", error.message);
        return null;
    }
}

