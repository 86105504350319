import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import ProductSelect from "./ProductSelect";
import ProductAvailability from "./ProductAvailability";
import {findProductsByQueryString} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";
import DatePicker from "react-datepicker";
import {formatDateToAmericanFormat, getOnlyDate} from "../../utils/utils";

const apiUrl = Config.apiUrl;
const ProductList = (props) => {
        const {onEditButtonClick, onRequestRentProduct, onNewButtonClick} = props;
        const [products, setProducts] = useState([]);
        const [localProducts, setLocalProducts] = useState([]);
        const [queryString, setQueryString] = useState('');
        const [showProductSelect, setShowProductSelect] = useState(false);
        const [productAvailability, setProductAvailability] = useState(undefined);
        const inputQueryString = useRef(null);
        const [, setLoading] = useRecoilState(loading);
        const [stockQuantityValue, setStockQuantityValue] = useState({quantity: 0, value: 0});
        const [startDate, setStartDate] = useState(undefined);
        const [endDate, setEndDate] = useState(undefined);

        // async function makeQuery(query) {
        //     try {
        //         setLoading(true);
        //         setProducts(await findProductsByQueryString(query));
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     } finally {
        //         setLoading(false);
        //     }
        // }
        async function makeQuery() {
            try {
                setLoading(true);
                const products = await findProductsByQueryString(queryString);
                setProducts(products);
                setLocalProducts(products);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        async function makeLocalQuery() {
            try {
                setLocalProducts(products.filter(p => p.name.toUpperCase().includes(queryString.toUpperCase())));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        useEffect(() => {
            if (inputQueryString.current) {
                inputQueryString.current.focus();
            }
            makeQuery();
        }, []);
        useEffect(() => {
            if (((!startDate && !endDate) || (startDate && endDate))) {
                makeLocalQuery();
            }
        }, [queryString, startDate, endDate]);

        useEffect(() => {
            setStockQuantityValue({
                quantity: products.reduce((acc, obj) => acc + obj.stock, 0),
                value: products.reduce((acc, obj) => acc + (obj.costValue * obj.stock), 0)
            });
        }, [products]);

        async function excluirProduto(id) {
            try {
                const url = apiUrl + '/api/products/' + id;
                await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
                await makeQuery();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        const handleInputChange = (e) => {
            const string = e.target.value;
            setQueryString(string);
        };

        const onAvailabilityClick = (product) => {
            setProductAvailability(product);
            setShowProductSelect(true);
        };

        const closeProductSelect = () => {
            setShowProductSelect(false);
        };


        const handleStartDateChange = (date) => {
            if (endDate && date > endDate) {
                setEndDate(date);
            }
            setStartDate(date);
        };

        const handleEndDateChange = (date) => {
            if (date && date < startDate) {
                alert('Data final não pode ser anterior à data inicial.');
                return;
            }
            setEndDate(date);
        };

        return (
            <>
                <div className="header-label">Consulta de Produtos</div>
                {showProductSelect && (
                    <ProductAvailability onClose={closeProductSelect} product={productAvailability}
                                         onRequestRentProduct={onRequestRentProduct}/>)}

                <div style={{
                    position: 'relative',
                    display: "flex",
                    alignItems: "flex-start",
                    height: '100%'
                }}>
                    <div style={{display: "flex"}}>
                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            Nome:
                            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                <input ref={inputQueryString} type="text" value={queryString}
                                       onChange={handleInputChange}/>
                            </div>
                        </div>
                        <div style={{marginTop: '5px', alignItems: 'center'}}>
                            Período de disponibilidade:
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <DatePicker
                                    className="datepicker"
                                    selected={startDate}
                                    minDate={new Date()}
                                    onChange={handleStartDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                />
                                até
                                <DatePicker
                                    className="datepicker"
                                    selected={endDate}
                                    minDate={new Date()}
                                    onChange={handleEndDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                />
                                {((!startDate && endDate) || (startDate && !endDate)) &&
                                    (<div style={{color: "red"}}>Preencha o período completo!</div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                    }}>
                        <button
                            onClick={onNewButtonClick}>
                            Novo
                        </button>
                    </div>
                </div>


                <table style={{width: '700px', height: '400px'}}>
                    <tr>
                        <th>
                            Código
                        </th>
                        <th>
                            Nome
                        </th>
                        <th>
                            Estoque
                        </th>
                        {startDate && endDate && (
                            <th>
                                Disponível
                            </th>
                        )}
                        <th>
                            Custo
                        </th>
                        <th/>
                        <th/>
                        <th/>
                    </tr>
                    <tbody style={{overflowY: 'auto'}}>
                    {localProducts.map((product, index) => {


                        let availability = 0;
                        if (startDate && endDate) {
                            const newStartDate = getOnlyDate(new Date(formatDateToAmericanFormat(startDate)));
                            const newEndDate = getOnlyDate(new Date(formatDateToAmericanFormat(endDate)));
                            const productAvailability = product.availabilities.find(a => getOnlyDate(newStartDate) >= getOnlyDate(new Date(a.startDate)) && (!a.endDate || getOnlyDate(newEndDate) <= getOnlyDate(new Date(a.endDate))));

                            if (productAvailability) {
                                availability = productAvailability.quantity;
                            }
                        }

                        if (product.code === 5) {
                            console.log('startDate: ' + !!startDate);
                            console.log('endDate: ' + !!endDate);
                            console.log('availability: ' + availability);
                            console.log('(startDate && endDate && availability === 0) '+(!!startDate && !!endDate && availability === 0));
                        }
                        return (

                            <tr style={{backgroundColor: (!!startDate && !!endDate && availability === 0) ? 'var(--error-color)': 'white' }}>
                                <td>
                                <div key={index}>{product.code}</div>
                                </td>
                                <td>
                                    <div key={index}>{product.name}</div>
                                </td>
                                <td>
                                    <div key={index}>{product.stock}</div>
                                </td>
                                {startDate && endDate &&
                                    (
                                        <td>
                                            <div key={index}>{availability}</div>
                                        </td>
                                    )}
                                <td>
                                    <div style={{textAlign: 'right'}}>{product.costValue.toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                    </div>
                                </td>
                                <td>

                                    <button onClick={async () => {
                                        await onAvailabilityClick(product);
                                    }}>Disponibilidade
                                    </button>

                                </td>
                                <td>
                                    <button onClick={async () => {
                                        await onEditButtonClick(product.id);
                                    }}>Editar
                                    </button>
                                </td>
                                <td>
                                    <button className="remove-button" onClick={async () => {
                                        // eslint-disable-next-line no-restricted-globals
                                        if (window.confirm('Deseja realmente excluir?')) {
                                            await excluirProduto(product.id);
                                        }
                                    }}>Excluir
                                    </button>
                                </td>

                            </tr>
                        );
                    })}
                    </tbody>
                    {localProducts.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
                </table>
                {localProducts.length > 0 &&
                    <>
                        <div className="total-label">
                            Quantidade: {stockQuantityValue.quantity}
                        </div>
                        <div className="total-label">Valor Total: R$ {stockQuantityValue.value.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                        </div>
                    </>
                }
            </>
        )
            ;
    }
;

export default ProductList;
