import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";
import WhatsAppButton from "../../core/WhatsAppButton";

const apiUrl = Config.apiUrl;
const CustomerList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    let [customers, setCustomers] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/customers/query=' + (query && query !== '' ? (query) : '');
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setCustomers(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        makeQuery('');
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    async function excluirCliente(id) {
        try {
            const url = apiUrl + '/api/customers/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };

    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

    const sortedCustomers = [...customers].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    return (
        <>
            <div className="header-label">Consulta de Clientes</div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange} />
                <button onClick={onNewButtonClick}>Novo</button>
            </div>

            <table style={{ width: '580px', height: '501px' }}>
                <thead>
                <tr>
                    <th style={{ cursor:"pointer", minWidth:"100px" }} onClick={() => handleSort('code')}>
                        Código {getSortIcon('code')}
                    </th>
                    <th style={{ cursor:"pointer" }} onClick={() => handleSort('name')}>
                        Nome {getSortIcon('name')}
                    </th>
                    <th />
                    <th />
                    <th />
                </tr>
                </thead>
                <tbody style={{ overflowY: 'auto' }}>
                {sortedCustomers.map((customer, index) => (
                    <tr key={index}>
                        <td>
                            <div>{customer.code}</div>
                        </td>
                        <td>
                            <div>{customer.name}</div>
                        </td>
                        <td>
                            {customer.phone && <WhatsAppButton phoneNumber={customer.phone} />}
                        </td>
                        <td>
                            <button onClick={async () => { await onEditButtonClick(customer.id); }}>Editar</button>
                        </td>
                        <td>
                            <button className="remove-button" onClick={async () => {
                                if (window.confirm('Deseja realmente excluir?')) {
                                    await excluirCliente(customer.id);
                                }
                            }}>Excluir</button>
                        </td>
                    </tr>
                ))}
                </tbody>
                {customers.length === 0 && (<tfoot><tr><td colSpan="5">Nenhum item encontrado.</td></tr></tfoot>)}
            </table>
        </>
    );
};

export default CustomerList;
