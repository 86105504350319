import BarChart from "../chart/BarChart";
import {useEffect, useState} from "react";
import {
    getAverageCustomerAgeChartData,
    getAverageTicketChartData,
    getCustomersChartData,
    getSalesChartData
} from "../../utils/requestUtils";
import {formatDateToString, getYearMonthFormat} from "../../utils/utils";
import LineChart from "../chart/LineChart";

const DashBoard = () => {

    const [salesByDate, setSalesByDate] = useState([]);
    const [salesByDateLabels, setSalesByDateLabels] = useState([]);
    const [salesByDateValues, setSalesByDateValues] = useState([]);

    const [customersByDate, setCustomersByDate] = useState([]);
    const [customersByDateLabels, setCustomersByDateLabels] = useState([]);
    const [customersByDateValues, setCustomersByDateValues] = useState([]);

    const [averageTicketByDate, setAverageTicketByDate] = useState([]);
    const [averageTicketByDateValues, setAverageTicketByDateValues] = useState([]);
    const [averageTicketByDateLabels, setAverageTicketByDateLabels] = useState([]);

    const [averageAverageTicketByDateValues, setAverageAverageTicketByDateValues] = useState([]);

    // const [averageCustomerAgeByDate, setAverageCustomerAgeByDate] = useState([]);
    // const [averageCustomerAgeByDateValues, setAverageCustomerAgeByDateValues] = useState([]);
    // const [averageCustomerAgeByDateLabels, setAverageCustomerAgeByDateLabels] = useState([]);

    const currentDate = new Date();
    const startDate = new Date(new Date().getFullYear(), currentDate.getMonth() - 12, 1);

    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    useEffect(() => {
        const fetchSalesData = async () => {
            const salesByDate = await getSalesChartData(startDate, endDate);
            setSalesByDate(salesByDate);

            const customersByDate = await getCustomersChartData(startDate, endDate);
            setCustomersByDate(customersByDate);

            const averageTicketByDate = await getAverageTicketChartData(startDate, endDate);
            setAverageTicketByDate(averageTicketByDate);

            // const averageCustomerAgeByDate = await getAverageCustomerAgeChartData(startDate, endDate);
            // setAverageCustomerAgeByDate(averageCustomerAgeByDate);
        };

        fetchSalesData();
    }, []);


    useEffect(() => {
        if (salesByDate && salesByDate.length > 0) {
            const labels = salesByDate.map(sale => getYearMonthFormat(new Date(sale.date)));
            const values = salesByDate.map(sale => sale.value);
            setSalesByDateLabels(labels);
            setSalesByDateValues(values);
        }
    }, [salesByDate]);

    useEffect(() => {
        if (customersByDate && customersByDate.length > 0) {
            const labels = customersByDate.map(customer => getYearMonthFormat(new Date(customer.date)));
            const values = customersByDate.map(customer => customer.value);
            setCustomersByDateLabels(labels);
            setCustomersByDateValues(values);
        }
    }, [customersByDate]);

    useEffect(() => {
        if (averageTicketByDate && averageTicketByDate.length > 0) {
            const labels = averageTicketByDate.map(averageTicket => getYearMonthFormat(new Date(averageTicket.date)));
            const values = averageTicketByDate.map(averageTicket => averageTicket.value);
            setAverageTicketByDateLabels(labels);
            setAverageTicketByDateValues(values);

            const averageValue = values.reduce((sum, value) => sum + value, 0) / values.length;
            const averageArray = values.map(() => averageValue);
            setAverageAverageTicketByDateValues(averageArray);

        }
    }, [averageTicketByDate]);

    // useEffect(() => {
    //     if (averageCustomerAgeByDate && averageCustomerAgeByDate.length > 0) {
    //         const labels = averageCustomerAgeByDate.map(averageCustomerAge => getYearMonthFormat(new Date(averageCustomerAge.date)));
    //         const values = averageCustomerAgeByDate.map(averageCustomerAge =>  Math.round(averageCustomerAge.value));
    //         setAverageCustomerAgeByDateLabels(labels);
    //         setAverageCustomerAgeByDateValues(values);
    //     }
    // }, [averageCustomerAgeByDate]);

    return <div className="dashboard-wrapper">

        <div className="header-label">Evolução do Faturamento</div>
        <BarChart labels={salesByDateLabels} dataValues={salesByDateValues} currencyformat colorIndex={0} color={"#36A2EB66"}/>

        <div style={{marginTop: '10px'}} className="header-label">Evolução dos Aluguéis</div>
        <BarChart labels={customersByDateLabels} dataValues={customersByDateValues} colorIndex={20} color={"#FF9F5E66"}/>

        <div style={{marginTop: '10px'}} className="header-label">Evolução do Ticket Médio</div>
        <LineChart labels={averageTicketByDateLabels}
                   dataValues={averageTicketByDateValues}
                   secondaryDataValues={averageAverageTicketByDateValues}
                   label1="Ticket médio"
                   label2="Média"
                   currencyformat/>

    </div>;
};

export default DashBoard;
