import React from 'react';

const CentralizedView = (props) => {
    const {title, children} = props;

    const contentStyle = {
        marginTop: '10px',
        maxHeight: '580px',
        padding: '20px',
        borderRadius: '10px',
        border: '2px solid #ccc',
        backgroundColor: '#fff',
        display: 'inline-block',
    };

    const centralizedContentStyle = {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
    }
    let isSmallScreen = document.documentElement.clientWidth < 1000;

    const mergedContentStyles = isSmallScreen ? contentStyle : {...contentStyle, ...centralizedContentStyle};
    return (
        <div style={mergedContentStyles}>
            {children}
        </div>
    );
};

export default CentralizedView;
