import React, {useState} from 'react';
import ModalDiv from "../../ModalDiv";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";

const TimePeriodPicker = ({onConfirm, onClose, startTime, endTime}) => {
    const [startTime_, setStartTime_] = useState(startTime);
    const [endTime_, setEndTime_] = useState(endTime);

    const handleTimeChange = (setTime, adjustEndTime = false) => (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        if (value.length > 4) {
            value = value.slice(0, 4);
        }
        if (value.length >= 3) {
            value = value.slice(0, 2) + ':' + value.slice(2);
        }
        setTime(value);

        if (adjustEndTime && value.length === 5) {
            const [hours, minutes] = value.split(':').map(Number);
            const endTimeDate = new Date();
            endTimeDate.setHours(hours);
            endTimeDate.setMinutes(minutes + 30);

            const adjustedHours = String(endTimeDate.getHours()).padStart(2, '0');
            const adjustedMinutes = String(endTimeDate.getMinutes()).padStart(2, '0');
            setEndTime_(`${adjustedHours}:${adjustedMinutes}`);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (startTime_.length === 5 && endTime_.length === 5 && startTime_ < endTime_) {
            onConfirm(startTime_, endTime_);
        } else {
            alert('Informe um horário válido.');
        }
    };

    return (
        <ModalDiv closeModal={onClose} style_={{paddingTop: '55px'}}>

            <div style={{display: 'flex'}}>
                <div htmlFor="startTime" style={{width: '40px', alignItems:"center", display:"flex"}}>Início:</div>
                <input autoComplete="off"
                    type="text"
                    id="startTime"
                    value={startTime_}
                    placeholder="hh:mm"
                    onChange={handleTimeChange(setStartTime_, true)}
                    required
                />
            </div>
            <div style={{display:'flex', marginTop:'5px'}}>
                <div htmlFor="endTime" style={{width:'40px', alignItems:"center", display:"flex"}}>Fim:</div>
                <input autoComplete="off"
                    type="text"
                    id="endTime"
                    value={endTime_}
                    placeholder="hh:mm"
                    onChange={handleTimeChange(setEndTime_)}
                    required
                />
            </div>
            <ActionButtonsManagerView>
                <ConfirmButton onClick={handleSubmit}/>
            </ActionButtonsManagerView>
        </ModalDiv>
    );
};

export default TimePeriodPicker;
