import React from 'react';

const ConfirmButton = (props) => {
    const {onClick} = props;
    return (
        <button className={"confirm-button"} onClick={onClick}>Confirmar</button>
    );
};

export default ConfirmButton;
