import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from './utils/httpRequest';
import './css/Select.css';
import ModalDiv from "./ModalDiv";
import Config from "./core/config";
import {useRecoilState} from "recoil";
import {loading} from "./atoms";
import Cookies from "js-cookie"; // Import a CSS file for styling

const apiUrl = Config.apiUrl;

const CustomerKeyLink = (props) => {
    const {onClose} = props;
    const [customerKeyLink, setCustomerKeyLink] = useState('');
    const inputCustomerKeyLink = useRef(null);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/customerKey';
            const response = await makeRequest(url, 'POST', {
                'Content-Type': 'application/json',
                'Useruuid':Cookies.get('userUUID')
            });
            var urlObj = new URL(window.location.href);
            var root = urlObj.origin;
            setCustomerKeyLink(root + "/autocadastro/" + response.customerKey);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (inputCustomerKeyLink.current) {
            inputCustomerKeyLink.current.focus();
        }
        makeQuery('');
    }, []);

    const handleInputChange = async (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            await makeQuery(string);
        }
        setCustomerKeyLink(string);
    };


    const handleQuery = async (e) => {
        await makeQuery(customerKeyLink);
    };

    function copyToClipboard() {
        navigator.clipboard.writeText(customerKeyLink)
            .then(() => {
                alert('Link copiado para a área de transferência.');
            })
            .catch((err) => {
                alert('Erro ao copiar link para a área de transferência.');
                console.error('Error copying to clipboard: ', err);
            });
    }

    return (
        <ModalDiv closeModal={onClose} style_={{width: '280px'}}>
            <h2>Link para cadastro de cliente:</h2>
            <input disabled={true} style={{width: '100%'}} ref={inputCustomerKeyLink} type="text"
                   value={customerKeyLink} onChange={handleInputChange}/>
            <div style={{marginTop: '5px', textAlign:"right"}}>
                <button onClick={copyToClipboard}>Copiar</button>
            </div>
        </ModalDiv>
    );
};

export default CustomerKeyLink;
