import {makeRequest} from "./httpRequest";
import Config from "../core/config";
import {adjustDateToBrowserTimeZone, formatDateToAmericanFormat, getOnlyDate} from "./utils";
import Cookies from "js-cookie";
import {saveAs} from 'file-saver';

const apiUrl = Config.apiUrl;


export async function findProductsByQueryString(query) {
    try {
        const url = apiUrl + '/api/products/query=' + (query && query !== '' ? query : '');
        const response: Product[] = await makeRequest(url, 'GET',
            {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export async function getRentalItem(id) {
    try {
        const url = apiUrl + '/api/rentalItem/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findRentalsByStringAndStatus(query, status) {
    try {
        if (!query) {
            query = '';
        }
        const url = apiUrl + '/api/productRented/findRentalsByStatus/query=' + query + '&status=' + status;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findPendingRentalItemItemByProductAvailability(productId) {
    try {
        const url = apiUrl + '/api/rentalItem/productId=' + productId;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export async function getProductById(id) {

    try {
        const url = apiUrl + '/api/products/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getReceivableById(id) {

    try {
        const url = apiUrl + '/api/receivables/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getCustomerById(id) {

    try {
        const url = apiUrl + '/api/customers/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getSupplierById(id) {

    try {
        const url = apiUrl + '/api/suppliers/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getPaymentById(id) {

    try {
        const url = apiUrl + '/api/payments/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getWaitingListById(id) {

    try {
        const url = apiUrl + '/api/waitingList/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getProductRentalPriceByProductId(productId) {

    try {
        const url = apiUrl + '/api/product_rental_prices/productId=' + productId;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getAllPaymentMethods() {

    try {
        const url = apiUrl + '/api/paymentMethods';
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getPendingWaitingList(productId) {
    const url = `${apiUrl}/api/waitingList/productId=${productId ? productId : 0}`;
    return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export function getProductAvailability(productId, rentalIds, days) {
    const url = `${apiUrl}/api/productAvailabilities/productId=${productId ? productId : 0}&rentalIds=${rentalIds}&days=${days}&currentDate=${formatDateToAmericanFormat(new Date())}`;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export function getProductUnavailability(productId) {
    const url = `${apiUrl}/api/productAvailabilities/unavailability/productId=${productId ? productId : 0}`;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function getProductAvailabilityFilterByQuantity(productId, rentalIds, currentDate, days, quantity) {
    const url = `${apiUrl}/api/productAvailabilities/productId=${productId ? productId : 0}&rentalIds=${rentalIds}&days=${days}&currentDate=${formatDateToAmericanFormat(currentDate)}`;
    const availabilities = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    return availabilities.filter((a) => a.quantity >= quantity)
}

export function getProductAvailabilityPerDate(productId, rentalIds, days, currentDate, startDate, endDate, quantity) {
    const url = `${apiUrl}/api/productAvailabilities/filterByDate/productId=${productId ? productId : 0}&rentalIds=${rentalIds}&days=${days}&currentDate=${formatDateToAmericanFormat(currentDate)}&startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}&quantity=${quantity}`;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function customerKeyExists(customerKey) {
    const url = apiUrl + '/api/customerKey/customerKey=' + customerKey;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function isSpaceBorneDelivery(customerKey) {
    const url = apiUrl + '/api/customerKey/isSpaceBorneDelivery/customerKey=' + customerKey;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function userUUIDExists() {
    const url = apiUrl + '/api/users/userUUID';
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function getRentalById(id) {

    try {
        const url = apiUrl + '/api/rentals/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getCompanyByUserUUID() {

    try {
        const url = apiUrl + '/api/companies/userUUID';
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function downloadContract(id, code) {
    try {
        const url = `${apiUrl}/api/contract/${id}`;
        const response = await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
        const data = await response.blob();

        saveAs(data, 'contrato_' + code + '.pdf');

    } catch (error) {
        console.error('Erro ao obter PDF:', error);
    }
}

export async function downloadDeliveryStatementByDate(id, code, date) {
    try {

        const url = `${apiUrl}/api/deliveryStatement?id=${id}&date=${formatDateToAmericanFormat(getOnlyDate(adjustDateToBrowserTimeZone(new Date(date))))}`;
        const response = await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
        const data = await response.blob();
        saveAs(data, 'termo_de_entrega_' + code + '.pdf');
    } catch (error) {
        console.error('Erro ao obter PDF:', error);
    }
}

export async function downloadDeliveryStatement(id, code) {
    try {
        const url = `${apiUrl}/api/deliveryStatement?id=${id}`;
        const response = await await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
        const data = await response.blob();

        saveAs(data, 'termo_de_entrega_' + code + '.pdf');

    } catch (error) {
        console.error('Erro ao obter PDF:', error);
    }
}

export async function getSalesChartData(startDate, endDate) {
    try {
        // if (lastSellerValuesStorage) {
        //     const now = new Date();
        //
        //     const time1 = lastSellerValuesStorage.getTime();
        //     const time2 = now.getTime();
        //
        //     const differenceInMillis = time2 - time1;
        //
        //     const fiveMinutesInMillis = 5 * 60 * 1000;
        //     if (differenceInMillis < fiveMinutesInMillis) {
        //         const saleItemsJson = localStorage.getItem(`sellerValues_${formatDateToAmericanFormat(startDate)}_${formatDateToAmericanFormat(endDate)}`);
        //         if (saleItemsJson !== undefined) {
        //             const saleItems = JSON.parse(saleItemsJson);
        //             if (saleItems !== undefined && saleItems !== null && saleItems.length > 0) {
        //                 return saleItems;
        //             }
        //         }
        //     } else {
        //         await invalidateCache();
        //     }
        // }

        const url = `${apiUrl}/api/dashboards/sales-chart-data-by-month?startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}`;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        // localStorage.setItem(`sellerValues_${formatDateToAmericanFormat(startDate)}_${formatDateToAmericanFormat(endDate)}`, JSON.stringify(response));
        //lastSellerValuesStorage = new Date();
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function getCustomersChartData(startDate, endDate) {
    try {
        const url = `${apiUrl}/api/dashboards/customers-chart-data-by-month?startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}`;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function getAverageTicketChartData(startDate, endDate) {
    try {
        const url = `${apiUrl}/api/dashboards/average-ticket-chart-data-by-month?startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}`;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function getAverageCustomerAgeChartData(startDate, endDate) {
    try {
        const url = `${apiUrl}/api/dashboards/average-customer-age-chart-data-by-month?startDate=${formatDateToAmericanFormat(startDate)}&endDate=${formatDateToAmericanFormat(endDate)}`;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}