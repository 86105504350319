export function formatPhoneNumber(value) {
    const numbers = value.replace(/\D/g, '');

    if (numbers.length >= 2) {


        let formattedNumber = numbers;
        const mobileRegex = /^\d{2}[6-9]\d/;
        const isMobile = mobileRegex.test(numbers);


        if (isMobile) {
            const ddd = numbers.substring(0, 2);
            const part1 = numbers.substring(2, 7);
            const part2 = numbers.substring(7, 10);

            if (numbers.length === 2) {
                formattedNumber = `(${ddd}`;
            }
            if (numbers.length > 2) {
                formattedNumber = `(${ddd}) ${part1}`;
            }
            if (numbers.length > 7) {
                formattedNumber = `(${ddd}) ${part1}-${part2}`;
            }
            const part3 = numbers.substring(10, 11);
            formattedNumber += `${part3}`;
        } else {
            const ddd = numbers.substring(0, 2);
            const part1 = numbers.substring(2, 6);
            const part2 = numbers.substring(6, 10);

            if (numbers.length === 2) {
                formattedNumber = `(${ddd}`;
            }
            if (numbers.length > 2) {
                formattedNumber = `(${ddd}) ${part1}`;
            }
            if (numbers.length > 6) {
                formattedNumber = `(${ddd}) ${part1}-${part2}`;
            }
        }


        return formattedNumber;
    } else {
        return numbers;
    }
}